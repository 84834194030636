import React, { useState, useEffect } from "react";
import lodash from "lodash";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { getCt } from "@/utils/common";
import browserizr, { isAndroid, isIOS } from "@wezom/browserizr";
/** @ts-ignore */
import { getWechat } from "@omc/browserizr";
import * as common from "@/common/common";
import "@/assets/styles/download.less";
import apis from "@/common/net/apis";
import barImg from "@/assets/images/bar.png";

const AutoPlaySwipeableViews: any = autoPlay(SwipeableViews);
const queryString = require("query-string");
let userAgent = navigator.userAgent.toLowerCase();
const isApp: any = userAgent.includes('mcd-ios') || userAgent.includes('mcd-android') || userAgent.includes('mcd-harmony');

const DOWNLOAD_PAGES: any = {
  MCD_THROUGH_APP_QQ:
    "https://a.app.qq.com/o/simple.jsp?pkgname=com.mcdonalds.gma.cn",
  MCD_ANDROID: "https://cdn.mcd.cn/cma/download/app-release.apk",
  MCD_IOS:
    "https://apps.apple.com/cn/app/%E9%BA%A6%E5%BD%93%E5%8A%B3mcdonalds-%E5%88%B0%E5%BA%97%E5%8F%96%E9%A4%90-%E9%BA%A6%E5%92%96%E5%95%A1-%E9%BA%A6%E4%B9%90%E9%80%81/id1054598922",
};

function genDownloadLink() {
  let href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.mcdonalds.gma.cn";
  if (userAgent.includes("iphone") || userAgent.includes("ios")) {
    href = "https://apps.apple.com/cn/app/id1054598922";
  } else if (userAgent.includes("wechat")) {
    userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.includes("android")) {
      return "";
    }
  } else if (
    userAgent.includes("android") &&
    (userAgent.includes("xiaomi") ||
      userAgent.includes("huawei") ||
      userAgent.includes("mi") ||
      userAgent.includes("redmi") ||
      userAgent.includes("oppo") ||
      userAgent.includes("vivo") ||
      userAgent.includes("360") ||
      userAgent.includes("heytapbrowser") ||
      userAgent.includes("hmscore"))
  ) {
    href = "market://details?id=com.mcdonalds.gma.cn";
  } else {
    href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.mcdonalds.gma.cn";
  }
  return href;
}

function openDownloadPage(link: string) {
  const sensors: any = (window as any).sensors;
  sensors.track("buttonClick", {
    belong_page: "活动页",
    button_name: "下载App",
  });
  window.location.href = link;
}

function downloadAtOnce() {
  const document: any = window.document;
  let hidden =
    document.hidden ||
    document.mozHidden ||
    document.msHidden ||
    document.webkitHidden;
  if (!hidden) {
    const href = genDownloadLink();
    if (href) openDownloadPage(href);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ banners = [] }) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [tipOpen, setTipOpen] = useState(false);
  const [isShowDownload, setIsShowDownload] = useState(false)
  const [descs, setDescs] = useState([
    "打开麦当劳APP失败",
    "如您未安装，请点击下载",
  ]);
  const [btnDesc, setBtnDesc] = useState("");
  const [imageConfig, setImages] = useState({
    topImage: "",
    banners: [] as string[],
  });
  const [iosJumpToAppStore, setIosJumpToAppStore] = useState(false); // 是否跳转appStore

  const tryOpenOrDownload = (
    isClickAction = false
  ) => {
    if (isClickAction && getWechat()) {
      // ags-4588 微信环境ios跳转appStore
      if (
        (userAgent.includes("iphone") || userAgent.includes("ios")) &&
        iosJumpToAppStore
      ) {
        openDownloadPage(DOWNLOAD_PAGES.MCD_IOS);
        return;
      } else {
        setPopupOpen(true);
        return;
      }
    }
    const path = window.location.pathname;
    downloadAtOnce();
  };

  useEffect(() => {
    document.body.style.fontSize = "14px";
    const setRem = () => {
      let vw = document.documentElement.clientWidth;
      document.documentElement.style.fontSize = vw / 7.5 + "px";
    };
    setRem();
    lodash.throttle(setRem, 100, { leading: true, trailing: false });
    window.onresize = setRem;
  });

  useEffect(() => {
    const queryObj = queryString.parse(window.location.search);
    querySettingImages();
    initJump();
  }, []);

  const initJump = async () => {
    const openUrl = await createLink();
    connectWebViewJavascriptBridge((bridge: any) => {
      bridge.callHandler("closeWebView", {}, function (data: any) {
      });
    })
    window.location.href = openUrl;
    setTimeout(() => {
      // tryOpenOrDownload();
      setIsShowDownload(true)
    }, 2000);
  }
  const connectWebViewJavascriptBridge = (callback: any) => {
    if (window.WebViewJavascriptBridge) {
      callback(window.WebViewJavascriptBridge);
    } else {
      document.addEventListener(
        'WebViewJavascriptBridgeReady'
        , function () {
          callback(window.WebViewJavascriptBridge)
        },
        false
      );
    }
  }

  const getModuleKey = () => {
    const queryObj = queryString.parse(window.location.search);
    let moduleKey;
    if (queryObj.moduleKey) return queryObj.moduleKey;
    const path = window.location.pathname;
    switch (true) {
      case path === "/app":
        moduleKey = "downloadTransfer";
        break;
      case path === "/transfer":
        moduleKey = "downloadTransfer";
        break;
      case path.endsWith("/app-bfcard.html"):
        moduleKey = "downloadBreakfast";
        break;
      case path.endsWith("app-download.html"):
        moduleKey = "downloadThrow";
        break;
      case path.endsWith("app-ohcard.html"):
        moduleKey = "downloadOhmycard";
        break;
    }
    return moduleKey;
  };

  const querySettingImages = () => {
    const moduleKey = getModuleKey();
    if (!moduleKey) return;
    apis
      .queryDownloadImageSetting(
        { moduleKey },
        {
          "Content-Type": "application/json	",
        },
        {
          timeout: 1000,
        }
      )
      .then(
        (res: any) => {
          if (res.success && res.code === 200) {
            const { positionList = [] } = res.data || {};
            if (positionList && positionList.length) {
              const { imageUrl = banners[0], extraInfo } = positionList[0];
              const {
                topPic = imageConfig.topImage,
                prompt = "",
                btnContent = "",
                jumpToAppStore = false,
              } = JSON.parse(extraInfo);
              setIosJumpToAppStore(jumpToAppStore);
              setImages({
                topImage: topPic,
                banners: [imageUrl],
              });
              if (prompt) {
                setDescs([prompt]);
              }
              if (btnContent) {
                setBtnDesc(btnContent);
              }
            } else {
              setFormerKey(moduleKey);
            }
          } else {
            setFormerKey(moduleKey);
          }
        },
        (rej: any) => {
          setFormerKey(moduleKey);
        }
      );
  };

  const setFormerKey = (key: string) => {
    const formerImg = {
      topImage: common.CDN_URLS.downloadTop,
      banners: banners as string[],
    };

    switch (key) {
      case "downloadBreakfast":
        formerImg.topImage = "https://img.mcd.cn/gallery/6cf94bd1b9f6a9ad.png";
        formerImg.banners = ["https://cdn.mcd.cn/cma/download/app-bfcard.jpg"];
        break;
      case "downloadOhmycard":
        formerImg.topImage = "https://img.mcd.cn/gallery/6cf94bd1b9f6a9ad.png";
        formerImg.banners = ["https://cdn.mcd.cn/cma/download/app-ohcard.jpg"];
        break;
      default:
        break;
    }
    setImages(formerImg);
  };

  const onDownloadClick = lodash.throttle(downloadAtOnce.bind(null), 500, { 'leading': true, 'trailing': false });

  const createLink = async () => {
    let href = "mcdapp://";
    const queryObj = queryString.parse(window.location.search);
    // 解析参数 判断环境 调用接口 获取openUrl
    if(!queryObj?.sceneType) return href
    const { success, data} = await apis.getNormalLink({
      "sceneType": queryObj.sceneType,
      "data": {
          "ct": browserizr.detect(isAndroid) ? 102 : 101,
          closeCurrent: true,
          ...queryObj,
      }
    })
    if (success) {
      return data.link || href
    }
    return href;
  }

  const onSureClick = () => {
    if (isApp) {
      setIsShowDownload(false)
      browserizr.detect(isAndroid) && connectWebViewJavascriptBridge((bridge: any) => {
        bridge.callHandler("closeWebView", {}, function (data: any) {
        });
      })
    } else {
      onDownloadClick()
    }
  }

  const onClose = () => {
    setIsShowDownload(false)
    browserizr.detect(isAndroid) &&  connectWebViewJavascriptBridge((bridge: any) => {
      bridge.callHandler("closeWebView", {}, function (data: any) {
      });
    })
  }

  return (
    <div className="container">
      <Modal
        open={popupOpen}
        closeOnEsc={true}
        onClose={() => {
          setPopupOpen(false);
        }}
        center
        classNames={{ modal: "modal-css", overlay: "overlay-css" }}
      >
        <div className="alert-container">
          <div>点击右上角在浏览器中打开</div>
          <div
            className="ok-button"
            onClick={() => {
              setPopupOpen(false);
            }}
          >
            确定
          </div>
        </div>
      </Modal>
      <Modal
        open={tipOpen}
        closeOnEsc={true}
        onClose={() => {
          setTipOpen(false);
        }}
        center
        classNames={{ modal: "modal-css", overlay: "overlay-css" }}
        styles={{
          modal: {
            width: "50%",
            margin: "auto",
          },
        }}
      >
        <div className="alert-container">
          {descs.map((desc: string) => (
            <div
              style={{
                fontSize: "16px",
                color: "#666",
                textAlign: "center",
              }}
              key={desc}
            >
              {desc}
            </div>
          ))}
          <div
            className="ok-button"
            id="ok-down"
            style={{
              minWidth: "70%",
              fontSize: "18px",
            }}
            onClick={() => {
              downloadAtOnce();
            }}
          >
            {btnDesc || "下载麦当劳APP"}
          </div>
        </div>
      </Modal>
      <div className="header">
        <a href="https://www.mcdonalds.com.cn/page/Privacy-Policy">
          <img
            src={imageConfig.topImage}
            alt=""
            onError={(e: any) => {
              e.target.onerror = null;
              e.target.src =
                "https://img.mcd.cn/gallery/6cf94bd1b9f6a9ad.png";
            }}
          />
        </a>
      </div>
      {imageConfig.banners.length ? (
        <AutoPlaySwipeableViews>
          {imageConfig.banners.map((item) => (
            <img
              key={item}
              alt=""
              onError={(e: any) => {
                e.target.onerror = null;
                e.target.src =
                  "https://img.mcd.cn/gallery/115cf520859b12da.png";
              }}
              onClick={() => onDownloadClick()}
              className={"banner-img"}
              src={item}
            />
          ))}
        </AutoPlaySwipeableViews>
      ) : null}
      <div className="footer footer-app" onClick={() => onDownloadClick()}>
        <div id="btn-download" className={"btn-download"}>
          {btnDesc || "立即下载"}
        </div>
      </div>
      {isShowDownload && <div className="download-pop">
          <div className="download-block">
            <img className="download-close" src="https://img.mcd.cn/gallery/639020210203103647460.png" onClick={() => onClose()} />
            <div className="download-title">无法打开？</div>
            <div className="download-text">{isApp ? '跳转失败，请确认跳转链接' : '唤起 麦当劳APP 失败，您可以点击下载安装'}</div>
            <div className="download-btn" onClick={() => onSureClick()}>{isApp ? '确定' : '立即下载'}</div>
          </div>
        </div>}
    </div>
  );
};
