import logo from '@/assets/images/logo.svg';
import '@/assets/styles/home.css';

function Home() {
  return (
    <div className="home">
      <header className="home-header">
        <img src={logo} className="logo" alt="logo" />
        <h1>
          Mobile McDonalds.
        </h1>
      </header>
    </div>
  );
}

export default Home;
