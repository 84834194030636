import React, { useState, useEffect } from "react";
import lodash from "lodash";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import browserizr, { isAndroid, isIOS } from "@wezom/browserizr";
/** @ts-ignore */
import { getWechat } from "@omc/browserizr";
import * as common from "@/common/common";
import "@/assets/styles/download.less";
import apis from "@/common/net/apis";
import barImg from "@/assets/images/bar.png";

const AutoPlaySwipeableViews: any = autoPlay(SwipeableViews);
const queryString = require("query-string");
let userAgent = navigator.userAgent.toLowerCase();
const sensors: any = (window as any).sensors;

const DOWNLOAD_PAGES: any = {
  MCD_THROUGH_APP_QQ:
    "https://a.app.qq.com/o/simple.jsp?pkgname=com.mcdonalds.gma.cn",
  MCD_ANDROID: "https://cdn.mcd.cn/cma/download/app-release.apk",
  MCD_IOS:
    "https://apps.apple.com/cn/app/%E9%BA%A6%E5%BD%93%E5%8A%B3mcdonalds-%E5%88%B0%E5%BA%97%E5%8F%96%E9%A4%90-%E9%BA%A6%E5%92%96%E5%95%A1-%E9%BA%A6%E4%B9%90%E9%80%81/id1054598922",
};

function genDownloadLink() {
  let href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.mcdonalds.gma.cn";
  if (userAgent.includes("iphone") || userAgent.includes("ios")) {
    href = "https://apps.apple.com/cn/app/id1054598922";
  } else if (userAgent.includes("wechat")) {
    userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.includes("android")) {
      return "";
    }
  } else if (userAgent.includes("android")) {
    href = "market://details?id=com.mcdonalds.gma.cn";
  } else {
    href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.mcdonalds.gma.cn";
  }
  return href;
}

function createLink(deeplink: string) {
  let href = deeplink || "mcdapp://";
  const args: any = [];
  const queryObj = queryString.parse(window.location.search);
  const pushArgs = (paramName: any) => {
    if (queryObj[paramName] !== undefined && queryObj[paramName] !== "")
      args.push([`${paramName}=${queryObj[paramName]}`]);
  };
  ["utm_term", "utm_source", "utm_campaign", "utm_medium", "utm_content"].map(
    (item) => {
      pushArgs(item);
    }
  );
  if (args.length > 0) {
    const querystr = args.join("&");
    if (href.match(/\?/)) {
      href += "&" + querystr;
    } else {
      href += "?" + querystr;
    }
  }
  return href;
}

function openDownloadPage(link: string) {
  sensors?.track("buttonClick", {
    belong_page: "活动页",
    button_name: "下载App",
  });
  window.location.href = link;
}

function downloadAtOnce(source: any) {
  const document: any = window.document;
  let hidden =
    document.hidden ||
    document.mozHidden ||
    document.msHidden ||
    document.webkitHidden;
  if (!hidden) {
    let href;
    if (source === "uc") {
      href = DOWNLOAD_PAGES.MCD_ANDROID;
      if (browserizr.detect(isAndroid)) {
        // href = DOWNLOAD_PAGES.MCD_ANDROID
      } else if (browserizr.detect(isIOS)) {
        href = DOWNLOAD_PAGES.MCD_IOS;
      }
    } else {
      href = genDownloadLink();
    }
    if (href) openDownloadPage(href);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ banners = [], source = "app", deeplink = "" }) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [tipOpen, setTipOpen] = useState(false);
  const [descs, setDescs] = useState([
    "打开麦当劳APP失败",
    "如您未安装，请点击下载",
  ]);
  const [btnDesc, setBtnDesc] = useState("");
  const [imageConfig, setImages] = useState({
    topImage: "",
    banners: [] as string[],
  });
  const [iosJumpToAppStore, setIosJumpToAppStore] = useState(false); // 是否跳转appStore

  const tryOpenOrDownload = (
    deeplink: string,
    source: any,
    isClickAction = false
  ) => {
    if (isClickAction && getWechat()) {
      // ags-4588 微信环境ios跳转appStore
      if (
        (userAgent.includes("iphone") || userAgent.includes("ios")) &&
        iosJumpToAppStore
      ) {
        openDownloadPage(DOWNLOAD_PAGES.MCD_IOS);
        return;
      } else {
        setPopupOpen(true);
        return;
      }
    }

    const path = window.location.pathname;
    // ou麦卡和早餐卡不主动跳转
    if (path.endsWith("/app-bfcard.html") || path.endsWith("app-ohcard.html")) {
      setTipOpen(true);
      return;
    }
    // setTipOpen(true)
    // setTimeout(function () {
    downloadAtOnce(source);
    // }, 2000);
  };

  useEffect(() => {
    const queryObj = queryString.parse(window.location.search);
  }, []);

  useEffect(() => {
    document.body.style.fontSize = "14px";
    const setRem = () => {
      let vw = document.documentElement.clientWidth;
      document.documentElement.style.fontSize = vw / 7.5 + "px";
    };
    setRem();
    lodash.throttle(setRem, 100, { leading: true, trailing: false });
    window.onresize = setRem;
  });

  useEffect(() => {
    const queryObj = queryString.parse(window.location.search);
    querySettingImages();
    if (!source || source == "cdn" || source == "app" || source == "download") {
      if (queryObj.deeplink) {
        window.sensors.quick("useAppPlugin", {
          deeplink: function (deeplink: any) {
            deeplink.init({ timeout: 800 }); // 等待唤起 App 的时间，默认 3000 ms
            window.document
              .getElementById("btn-download")
              ?.addEventListener("click", function (e: any) {
                e.preventDefault();
                deeplink.openDeepLink();
              });
          },
        });
      } else {
        window.location.href = createLink(deeplink);
        const path = window.location.pathname;
        // ou麦卡和早餐卡和微信环境下不主动跳转
        if (
          path.endsWith("/app-bfcard.html") ||
          path.endsWith("app-ohcard.html") ||
          getWechat()
        ) {
          return;
        }
        setTimeout(() => {
          tryOpenOrDownload(deeplink, source);
        }, 2000);
      }
    }
  }, []);

  const getModuleKey = () => {
    const queryObj = queryString.parse(window.location.search);
    let moduleKey;
    if (queryObj.moduleKey) return queryObj.moduleKey;
    const path = window.location.pathname;
    switch (true) {
      case path === "/app":
        moduleKey = "downloadTransfer";
        break;
      case path === "/download":
        moduleKey = "downloadTransfer";
        break;
      case path.endsWith("/app-bfcard.html"):
        moduleKey = "downloadBreakfast";
        break;
      case path.endsWith("app-download.html"):
        moduleKey = "downloadThrow";
        break;
      case path.endsWith("app-ohcard.html"):
        moduleKey = "downloadOhmycard";
        break;
    }
    return moduleKey;
  };

  const querySettingImages = () => {
    const moduleKey = getModuleKey();
    if (!moduleKey) return;
    apis
      .queryDownloadImageSetting(
        { moduleKey },
        {
          "Content-Type": "application/json	",
        },
        {
          timeout: 1000,
        }
      )
      .then(
        (res: any) => {
          if (res.success && res.code === 200) {
            const { positionList = [] } = res.data || {};
            if (positionList && positionList.length) {
              const { imageUrl = banners[0], extraInfo } = positionList[0];
              const {
                topPic = imageConfig.topImage,
                prompt = "",
                btnContent = "",
                jumpToAppStore = false,
              } = JSON.parse(extraInfo);
              setIosJumpToAppStore(jumpToAppStore);
              setImages({
                topImage: topPic,
                banners: [imageUrl],
              });
              if (prompt) {
                setDescs([prompt]);
              }
              if (btnContent) {
                setBtnDesc(btnContent);
              }
            } else {
              setFormerKey(moduleKey);
            }
          } else {
            setFormerKey(moduleKey);
          }
        },
        (rej: any) => {
          setFormerKey(moduleKey);
        }
      );
  };

  const setFormerKey = (key: string) => {
    const formerImg = {
      topImage: common.CDN_URLS.downloadTop,
      banners: banners as string[],
    };

    switch (key) {
      case "downloadBreakfast":
        formerImg.topImage = "https://img.mcd.cn/gallery/6cf94bd1b9f6a9ad.png";
        formerImg.banners = ["https://cdn.mcd.cn/cma/download/app-bfcard.jpg"];
        break;
      case "downloadOhmycard":
        formerImg.topImage = "https://img.mcd.cn/gallery/6cf94bd1b9f6a9ad.png";
        formerImg.banners = ["https://cdn.mcd.cn/cma/download/app-ohcard.jpg"];
        break;
      default:
        break;
    }
    setImages(formerImg);
  };

  const onDownloadClick = lodash.throttle(
    tryOpenOrDownload.bind(null, deeplink, source, true),
    500,
    { leading: true, trailing: false }
  );
  return (
    <div className="container">
      <Modal
        open={popupOpen}
        closeOnEsc={true}
        onClose={() => {
          setPopupOpen(false);
        }}
        center
        classNames={{ modal: "modal-css", overlay: "overlay-css" }}
      >
        <div className="alert-container">
          <div>点击右上角在浏览器中打开</div>
          <div
            className="ok-button"
            onClick={() => {
              setPopupOpen(false);
            }}
          >
            确定
          </div>
        </div>
      </Modal>
      <Modal
        open={tipOpen}
        closeOnEsc={true}
        onClose={() => {
          setTipOpen(false);
        }}
        center
        classNames={{ modal: "modal-css", overlay: "overlay-css" }}
        styles={{
          modal: {
            width: "50%",
            margin: "auto",
          },
        }}
      >
        <div className="alert-container">
          {descs.map((desc: string) => (
            <div
              style={{
                fontSize: "16px",
                color: "#666",
                textAlign: "center",
              }}
              key={desc}
            >
              {desc}
            </div>
          ))}
          <div
            className="ok-button"
            id="ok-down"
            style={{
              minWidth: "70%",
              fontSize: "18px",
            }}
            onClick={() => {
              sensors?.track("buttonClick", {
                belong_page: window.location.href || "",
                button_name: "下载APP",
                module_name: "下载弹窗",
              });
              downloadAtOnce(source);
            }}
          >
            {btnDesc || "下载麦当劳APP"}
          </div>
        </div>
      </Modal>
      {source !== "cdn" && source !== "uc" && (
        <div className="header">
          <img
            src={imageConfig.topImage}
            alt=""
            onError={(e: any) => {
              e.target.onerror = null;
              e.target.src = "https://img.mcd.cn/gallery/6cf94bd1b9f6a9ad.png";
            }}
          />
          <span
            className="jump-span jump-left"
            onClick={() => {
              window.location.href =
                "https://www.mcdonalds.com.cn/page/Privacy-Policy";
            }}
          />
          <span
            className="jump-span jump-right"
            onClick={() => {
              window.location.href =
                "https://cdn.mcd.cn/cms/pages/CMAIntroduction.html";
            }}
          />
        </div>
      )}
      {imageConfig.banners.length ? (
        <AutoPlaySwipeableViews>
          {imageConfig.banners.map((item) => (
            <img
              key={item}
              alt=""
              onError={(e: any) => {
                e.target.onerror = null;
                e.target.src =
                  "https://img.mcd.cn/gallery/115cf520859b12da.png";
              }}
              // onClick={() => {
              //   sensors?.track("buttonClick", {
              //     belong_page: window.location.href || "",
              //     button_name: "下载APP",
              //     module_name: "背景图片",
              //   });
              //   onDownloadClick();
              // }}
              className={"banner-img"}
              src={item}
            />
          ))}
        </AutoPlaySwipeableViews>
      ) : null}
      {(!source || source === "app") && (
        <div
          className="footer footer-app"
          onClick={() => {
            sensors?.track("buttonClick", {
              belong_page: window.location.href || "",
              button_name: "下载APP",
              module_name: "下载按钮",
            });
            onDownloadClick();
          }}
        >
          <div id="btn-download" className={"btn-download"}>
            {btnDesc || "立即下载"}
          </div>
        </div>
      )}
      {/* (source === 'cdn' || source === 'uc') && <div className="footer footer-cdn">
        <div className={"bottom-bar"}>
          <img src={logoInBarImg} className="logo-in-bar" alt="small logo" />
          <div className="info-block">
            <ul className="info-1">
              <li>开发者：金拱门（中国）有限公司</li>
              <li>应用涉及权限及隐私链接：
                <a href="https://www.mcdonalds.com.cn/page/Privacy-Policy" className="btn-privacy">查看详情</a>
              </li>
            </ul>
            <ul className="info-2">
              <li>应用版本：6.0.3.0</li>
              <li>更新时间：2021/02/09</li>
            </ul>
          </div>
        </div>
      </div>} */}
      {(source === "cdn" || source === "uc") && (
        <div className="footer footer-cdn">
          <div className={"bottom-bar"}>
            <a
              href="https://www.mcdonalds.com.cn/page/Privacy-Policy"
              className="link-privacy"
            >
              <img src={barImg} className="img-bar" alt="bar image" />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};
