import {useEffect, useState} from "react";
import Cookies from "js-cookie";
import tool from "@/common/utils/tool";
import { getCt } from "@/utils/common";
import apis from "@/common/net/apis";
import cx from "classnames";
import "@/assets/styles/reward-detail.less";
import ArrowIcon from "@/assets/images/learn-more.png";
import NullImg from "@/assets/images/null-img.png";
import {client} from "@/common/common";

interface Reward {
  prizeType: number; //  1:优惠券 2：权益卡 3：实物 4：ecs邮寄 5：积分
  image: string;
  name: string;
  recordTime: string; // 中奖时间
  timeRemindText: string;
  btnText: string;
  btnShow: boolean; //是否展示按钮
  btnUrl: string; // 按钮跳转链接
  tagTitle: string; //奖品类型名称
}

export default () => {
  const sid = tool.getSearchByKey("sid") || "";
  Cookies.set("sid", sid);
  const taskId: any = tool.getSearchByKey("taskId");
  const [rewardInfo, setRewardInfo] = useState([] as Reward[]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = '我的奖励';
    getRewardDetail();
  }, []);
  const ct = getCt();
  const getRewardDetail = () => {
    try {
      apis.getRewardDetail(taskId, {sid, ct}).then(
        (res: any) => {
          if (res && res.data) {
            const {rewards} = res.data;
            setRewardInfo(rewards);
          }
          setLoading(false);
        },
        () => {
        }
      );
    } catch (e){
      console.log(e)
    }
  };

  const checkDetail = (btnUrl?: string) => {
    if (btnUrl) {
      const wx = (window as any).wx;
      if (client.weappMiniContext()) {
        wx.miniProgram.getEnv(function (res: { miniprogram: any; }) {
          if (res.miniprogram) {
            //微信小程序
            wx.miniProgram.navigateTo({
              url: btnUrl,
              fail:() => window.location.href = btnUrl
            })
          } else {
            //微信网页
            window.location.href = btnUrl;
          }
        });
      } else {
        window.location.href = btnUrl;
      }
    }
  }
  return (
    <div style={{ width: '100vw', height: '100vh', paddingTop: '18px', background: '#eee'}}>
      {loading ? null : (
        rewardInfo?.length === 0 ? (
          <div className="null-reward">
            <img src={NullImg} className="null-reward-img"/>
            <div className="null-reward-content">
              你还没有得到奖励哦～
            </div>
            <div className="null-reward-content">
              快去邀请好友吧！
            </div>
          </div>
        ) : (
          <div className="reward-detail">
            {rewardInfo?.map(item => {
              return (
                <div className="reward-detail-item" key={item.recordTime}>
                  <div className="yellow-div" />
                  <div className="reward-img-container">
                    <img src={item.image}/>
                  </div>
                  <div className="reward-detail-container">
                    <div
                      className={cx("reward-tag-title", {
                        "yellow-tag": item.prizeType === 5
                      })}
                    >
                      {item.tagTitle}
                    </div>
                    <div className="reward-name">{item.name}</div>
                    <div className="reward-record-time">获取时间: {item.recordTime}</div>
                    <div className="reward-time-text">{item.timeRemindText}</div>
                    {item.btnShow && (
                      <div
                        className="btn-text"
                        onClick={() => checkDetail(item.btnUrl)}
                      >
                        {item.btnText}
                        <img src={ArrowIcon} className="btn-img"/>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )
      )}
    </div>
  );
};
