// https://qr.mcd.cn/wv?relativePath=%2FtaroH5.html%23%2Fpages%2Fdt%2Fpages%2FscanningSignIn%2Findex%3FstoreCode%3D1990999
// https://m-sit.mcdchina.net/wv?relativePath=%2FtaroH5.html%23%2Fpages%2Fdt%2Fpages%2FscanningSignIn%2Findex%3FstoreCode%3D1990999
import React, { useEffect, useState } from 'react';
import loading from '@/assets/images/loading.gif';
import '@/assets/styles/qr-sign-in.less';

function SignIn() {
  useEffect(()=>{
    const urlParams = new URL(window.location.href);
    const relativePath = urlParams.searchParams.get("relativePath")
    const host = window.location.host;
    const protocol = window.location.protocol;
    if(relativePath){
      window.location.href = protocol + '//' + host + relativePath
    }
  }, [])

  return (
    <div className="qr-sign-in">
      <div>
        <div className="header">
          <img className="loading" src={loading} />
        </div>
        <div className="actions">
          <div className="text">正在加载</div>
        </div>
      </div>
    </div>
  )
}

export default SignIn

