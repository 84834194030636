import { Empty } from 'antd';
import '@/assets/styles/page-not-found.less';

function PageNotFound() {
  return (
    <div className="page-not-found">
      <Empty
        description={
          <span>
            页面没找到
          </span>
        }
      >
      </Empty>

    </div>
  );
}

export default PageNotFound;
