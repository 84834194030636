import React from 'react';
export default () => (
  <svg version="1.1" id="Layer_1" x="0px" y="0px" width="15px" height="11px" viewBox="0 0 45 33" enableBackground="new 0 0 45 33">  <image id="image0" width="45" height="33" x="0" y="0"
    href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAAAhCAQAAADn0epqAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElN
RQflBBQBEB4RqQOcAAABsUlEQVRIx+2WPWvCUBSG39jcGkOjIF1SKnSxoEihSAu2VIqDW6D/wN/W
X9ChuqvUDl0EO7g4uLQSqh2aEEISmw5JyYeaD6uD0LOEm5v7cM55cnND7TWwpUhsC7xVNG1djPuN
Qhu72xAbzbObQ6azHvSHkMptBpwsKYK3IYxeI5Uq/Tcsz5L6vPw7ojxbRmLbX5/rglM5/QbEGdtZ
U2MAAKcIyeI62CpNKnrNAicG1j27BXqHeTMuQYD5BTk+fJooccDprHILzqqb6ckifebKGlBHmUdM
AcDk40lNFhXBAlPjfFMWfQ0BgJlktOxiIkvlWVKfX1iF0z29M9ScOd9yrX/wrl6DA8zTZz4dIjWV
06/AAACmme5M8s4u7EZZzDddUkursLY4BgASA6PlBy9kDQBDDY7UMjlaJtUlTmXaTn8Ds7bCI/XO
L9Uj7mE5OODz5JJK3FKDxIU2xIlFqcHiYqABWSw0RxXzBACnCGRi8naxA62PkAh9d91SbfBKcRF7
7Q5HarC4mFlbMZPQ2j//LtAv6ijaishoAND61deuEf35WGdjHPCOn+g7hqb+/1S98QO18q32KLa3
gAAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMS0wNC0xOVQxNzoxNjozMCswODowMM/WregAAAAldEVY
dGRhdGU6bW9kaWZ5ADIwMjEtMDQtMTlUMTc6MTY6MzArMDg6MDC+ixVUAAAAIHRFWHRzb2Z0d2Fy
ZQBodHRwczovL2ltYWdlbWFnaWNrLm9yZ7zPHZ0AAAAYdEVYdFRodW1iOjpEb2N1bWVudDo6UGFn
ZXMAMaf/uy8AAAAXdEVYdFRodW1iOjpJbWFnZTo6SGVpZ2h0ADMzX/PIYgAAABZ0RVh0VGh1bWI6
OkltYWdlOjpXaWR0aAA0NQF+Ox0AAAAZdEVYdFRodW1iOjpNaW1ldHlwZQBpbWFnZS9wbmc/slZO
AAAAF3RFWHRUaHVtYjo6TVRpbWUAMTYxODgyMzc5MK3h4rUAAAARdEVYdFRodW1iOjpTaXplADMz
N0JC7vc9vwAAAEd0RVh0VGh1bWI6OlVSSQBmaWxlOi8vL2FwcC90bXAvaW1hZ2VsYy9pbWd2aWV3
Ml85XzE2MDk5MDQwNDYwOTY2MzI0XzI2OF9bMF0YP9SfAAAAAElFTkSuQmCC" ></image>
</svg>
);