import React, {useEffect, useRef, useState} from 'react';
import cx from 'classnames';
import Cookies from "js-cookie";
import tool from "@/common/utils/tool";
import {client} from "@/common/common";
import apis from "@/common/net/apis";
import '@/assets/styles/acceptInviteDownload.less';

const STATUS: { [key: string]: number } = {
  '可领奖': 1,
  '已经获得奖': 2,
  '无奖励': 3,
  '自己点': 4,
  '未登录': 5,
  '已获得别人任务的奖': 6,
}

export default () => {
  const utm_source = tool.getSearchByKey('utm_source');
  const userAgent: any = navigator.userAgent.toLowerCase();
  const sid = (Cookies.get('sid') || tool.getSearchByKey('sid'));
  const isApp = userAgent.includes('mcd-ios') || userAgent.includes('mcd-android') || userAgent.includes('mcd-harmony');
  const isMinPr = client.weappMiniContext();
  const taskId: any = tool.getSearchByKey('taskId');
  const inviteCode: any = tool.getSearchByKey('activityCode') || '1hrdnpiwb7lur';
  
  const [activityInfo, setActivityInfo] = useState({
    status: 1, // 接收状态 1：可领奖 2：已经获得奖 3：无奖励 5：自己点 6：未登录 7：以获得别人任务的奖
    activityStatus: undefined, //活动状态 活动呈现状态 0 待发布；1 待上线；2 上线中；3 已结束；4 已下线
    activityCoverImg: '',
    ruleDesc: '',
    bgColor: '',
    jumpUrl: '',
    repostMsg: '',
    endTime: '',
    shareImg: ''
  });
  
  const isOldPeople = activityInfo.status === 7;
  
  const getText = () => {
    let text = '立即领取';
    switch (activityInfo.status) {
      case 1: // 可领奖
      case 5:
      case 7:
        text = '立即领取';
        break;
      case 2:
      case 3: // 已领奖或者没奖励
        text = '立即下载APP使用';
        break;
      case 6: // 可领奖
        text = '注册领券';
        break;
    }
    return text;
  }
  
  useEffect(() => {
    if (!sid) {
      if (isApp) {
        window.location.href = 'https://mcd.cn/login';
      }
    } else {
      getActivityDetail();
    }
  }, [])
  
  const getActivityDetail = () => {
    apis.acceptCardInviteBasic({taskId, inviteCode}, {sid}).then((res: any) => {
      if (res) {
        setActivityInfo(res.data || {})
      }
    }, () => {})
  }
  
  const acceptInvite = () => {
    if (activityInfo.status === 1 || activityInfo.status === 5) {
      apis.acceptCardInvite({taskId, inviteCode}, {sid}).then((res: any) => {
        if (res) {
          window.location.href = activityInfo.jumpUrl;
        }
      }, () => {})
    } else {
      window.location.href = activityInfo.jumpUrl;
    }
  }
  
  return (
    <div className="accept-invite" style={{backgroundColor: activityInfo.bgColor}}>
      <div className="content-container">
        <button
          className={cx('get-btn', {
            'get-btn-disable': isOldPeople,
          })}
          disabled={isOldPeople}
          onClick={() => acceptInvite()}
        >{getText()}</button>
        {isOldPeople && <div className="old-people">你已经是APP老用户了</div>}
        <div className="accept-invite-btn-group">
          <div className="btn">查看卡包</div>
          <div className="btn">分享好友</div>
        </div>
        <div className="invite-content">{activityInfo.ruleDesc}</div>
      </div>
      <div className="accept-invite-cover">
        <img src={activityInfo.activityCoverImg}/>
      </div>
    </div>
  )
}
