import React from "react";
import "@/assets/styles/redpacket/index.less";
const EMPTY_IMG = "https://img.mcd.cn/gallery/538020210128050155064.png";

export default (props: any) => {
  const {
    chatId,
    groupFlag,
    isActivityEmpty,
    isStoreStaff,
    activityName,
    beginTime,
    endTime,
  } = props;
  const getDate = () => {
    if (beginTime && endTime) {
      const begin = beginTime.split("-");
      const end = endTime.split("-");
      if (beginTime == endTime) {
        return `${begin[1]}月${begin[2]}日`;
      } else {
        return `${begin[1]}月${begin[2]}日-${end[1]}月${end[2]}日`;
      }
    }

    // let today = new Date();
    // let month: any = today.getMonth() + 1;
    // let date: any = today.getDate();
    // month = month < 10 ? `0${month}` : month;
    // date = date < 10 ? `0${date}` : date;
    // return `${month}月${date}日`
  };
  const GroupChatEle = () => {
    if (isActivityEmpty || !chatId) {
      return (
        <div>
          <img src={EMPTY_IMG} className="empty-img" />
          <p className="empty-text">
            {chatId ? "目前无活动，稍后再来吧" : "请从群聊访问"}
          </p>
        </div>
      );
    } else {
      return <p className="text">{activityName}</p>;
    }
  };

  const SingleChatELe = () => {
    if (isActivityEmpty || !isStoreStaff || groupFlag === 3) {
      return (
        <div>
          <img src={EMPTY_IMG} className="empty-img" />
          <p className="empty-text">
            {groupFlag === 3
              ? "请从聊天工具栏进入"
              : isActivityEmpty
              ? "目前无活动，稍后再来吧"
              : "仅限门店员工可使用"}
          </p>
        </div>
      );
    } else {
      return <p className="text">{activityName}</p>;
    }
  };

  return (
    <div className="top-container">
      <p className="date">{getDate()}</p>
      {groupFlag === 1 ? <GroupChatEle /> : <SingleChatELe />}
    </div>
  );
};
