import React from 'react';
import RedpacketItem from './RedpacketItem';

export default (props: any) => {
  const { sendList, unSendList, otherGroupList, chatId, activityCode, getRedpacketList, wsid, shareInfo, groupFlag, activityInfo, currentCgmStoreCode } = props;
  return (<div className="redpacket-list-wrapper">
    <div className="send-title">本群已发 {sendList && sendList.length || 0} 个</div>
    <div className="tip">请合理控制本群发放数量和时段</div>
    {/* {
      sendList && sendList.length > 0 ?
        <div className="redpacket-list">
          {
            sendList && sendList.map((r: any) =>
              <RedpacketItem
                redpacket={r}
                key={r.redpacketCode}
                chatId={chatId}
                activityCode={activityCode}
                getRedpacketList={getRedpacketList}
                wsid={wsid}
                shareInfo={shareInfo}
                groupFlag={groupFlag}
              ></RedpacketItem>
            )
          }</div> :
        <div className="no-sendlist">无已发送红包</div>
    } */}

    <div className="send-title not-send">未使用 {unSendList && unSendList.length || 0} 个</div>
    {
      unSendList && unSendList.length > 0 ? 
      <div className="redpacket-list">
      {
        unSendList && unSendList.map((r: any) =>
          <RedpacketItem
            redpacket={r}
            key={r.redpacketCode}
            chatId={chatId}
            activityCode={activityCode}
            getRedpacketList={getRedpacketList}
            wsid={wsid}
            shareInfo={shareInfo}
            groupFlag={groupFlag}
            activityInfo={activityInfo}
            currentCgmStoreCode={currentCgmStoreCode}
          ></RedpacketItem>
        )
      }
    </div> : <div className="no-sendlist">无可用</div>
    }
    <div className="send-title not-send">其他群已发 {otherGroupList && otherGroupList.length || 0} 个</div>
    {/* {
      otherGroupList && otherGroupList.length > 0 ? 
      <div className="redpacket-list">
      {
        otherGroupList && otherGroupList.map((r: any) =>
          <RedpacketItem
            redpacket={r}
            key={r.redpacketCode}
            chatId={chatId}
            activityCode={activityCode}
            getRedpacketList={getRedpacketList}
            wsid={wsid}
            shareInfo={shareInfo}
            groupFlag={groupFlag}
            styleType={styleType}
          ></RedpacketItem>
        )
      }
    </div> : <div className="no-sendlist">无</div>
    } */}
  </div>)
}