import React from 'react';
import RedpacketItem from './RedpacketItem';
import {InfiniteScroll} from 'antd-mobile'

export default (props: any) => {
  const { sendList, unSendList, otherGroupList, chatId, activityCode, getRedpacketList, wsid, shareInfo, groupFlag,hasMore, pageNum , sendTotal, activityInfo, currentCgmStoreCode} = props;
  const mergedSendList = (sendList || []).concat(otherGroupList || [])
  const loadMore = async () => {
    await getRedpacketList(chatId, 0, pageNum + 1)
  }
  return (<div className="redpacket-list-wrapper">
     <div className="send-title not-send">未使用 {unSendList && unSendList.length || 0} 个</div>
    {
      unSendList && unSendList.length > 0 ? 
      <div className="redpacket-list">
      {
        unSendList && unSendList.map((r: any) =>
          <RedpacketItem
            redpacket={r}
            key={r.redpacketCode}
            chatId={chatId}
            activityCode={activityCode}
            getRedpacketList={getRedpacketList}
            wsid={wsid}
            shareInfo={shareInfo}
            groupFlag={groupFlag}
            activityInfo={activityInfo}
            currentCgmStoreCode={currentCgmStoreCode}
          ></RedpacketItem>
        )
      }
      </div>
     : <div className="no-sendlist">无可用</div>
    }
    <div className="send-title">已发送 {sendTotal || 0} 个</div>
    <div className="tip">请勿多次向同一顾客发送</div>
    {/* {
      mergedSendList && mergedSendList.length > 0 ?
       <div> 
         <div className="redpacket-list">
          {
            mergedSendList.map((r: any) =>
              <RedpacketItem
                redpacket={r}
                key={r.redpacketCode}
                chatId={chatId}
                activityCode={activityCode}
                getRedpacketList={getRedpacketList}
                wsid={wsid}
                shareInfo={shareInfo}
                groupFlag={groupFlag}
              ></RedpacketItem>
            )
          }
          </div> 
          <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={50}/>
        </div>
        :<div className="no-sendlist">无已发送红包</div>
    } */}
  </div>)
}