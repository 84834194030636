import 'antd/dist/antd.less';
import sendCode from './sendCode';
import api from '@/common/net/apis';
// @ts-ignore
import Toast from 'react-simple-toast';
import React, { useState, useEffect } from 'react';
// @ts-ignore
import { aesEncrypt } from '@omc/crypto';
import { Button, Input } from 'antd';
import tickImg from "@/assets/images/tick.png";
import logoImg from "@/assets/images/logos.png";
import notTickImg from "@/assets/images/not-tick.png";
import Icon, { createFromIconfontCN } from '@ant-design/icons';
import Cookies from 'js-cookie';
import common from '@omc/common';
import "@/assets/styles/login/Index.less";

const queryString: any = require('query-string');
let phoneFlag = false; // 手机号码是否正确

// 验证码图标
const SendIcon = () => <Icon component={sendCode}/>;

const iconFontStyle = {
  fontSize: '17px',
} as React.CSSProperties

// 手机号图标
const McDIcon = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_2298825_rqumg09icp.js',
});

export default (() => {
  // 是否同意《使用条款》和《麦当劳隐私权政策》标识
  const [agreeTerm, setAgreeTerm] = useState(false);
  // 是否同意加入《中信优享+用户服务协议》标识
  const [agreeZXTerm, setAgreeZXTerm] = useState(false);
  // 验证码文案
  const [verCode, setVerCode] = useState('');
  // 验证码按钮文案
  const [verCodeText, setVerCodeText] = useState('获取验证码');
  // 手机号
  const [phone, setPhone] = useState('');
  // 带有后缀图标的 input
  const [mobileSuffix, setMobileSuffix]: any = useState(null);
  // 手机号错误文本
  const [mobileError, setMobileError] = useState('');
  // 验证码错误文本
  const [codeError, setCodeError] = useState('');
  // 按钮是否置灰标识
  const [enabledVCActionClass, setEnabledVCActionClass]: any = useState(null);
  // 错误情况top下下边框样式表
  const [inputErrorTopClass, setInputTopErrorClass]: any = useState('');
  // 错误情况bottom下下边框样式表
  const [inputErrorBottomClass, setInputBottomErrorClass]: any = useState('');
  // 错误情况下样式表
  const [cursorPos, setCursorPos]: any = useState(0);
  // 验证码文案样式
  const [verCodeClass, setVerCodeClass] = useState('');
  // 是否开始输入手机号或者验证码标识
  const [keyboard, setKeyboard] = useState(true);
  // 初始屏幕高度
  const windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
  // 验证码成功发送标识
  const [flag, setFlag] = useState(false);

  // 判断如果已经登陆返回之前页面
  if (Cookies.get('sid')) {
    const referrer = document.referrer;
    if (referrer) {
      window.location.href = referrer;
    } else {
      window.location.href = 'https://www.mcd.cn/';
    }
  }

  function removeSidFromUrl(url: string) {
    const parse = require('url-parse');
    const l: any = parse(url, true);

    let href: string = url;
    if (l.query.sid) {
      href = `${l.protocol}//${l.host}${l.pathname}`;
      delete l.query.sid;
      const search = common.helpers.joinObjectToUrlPath(l.query);
      if (search) href += '?' + search;
      if (l.hash) href += l.hash;
    }

    return href;
  }

  // 登陆成功操作
  const loginSuccess = (res: any) => {
    if (res.success) {
      const sid: any = res.data.sid;
      common.helpers.setSIDCookie(sid);
      let referrer: any = document.referrer;

      if (referrer) {
        referrer = removeSidFromUrl(referrer);
        window.location.href = referrer;
        // if (referrer.includes('?')) {
        //   window.location.href = referrer + '&sid=' + sid;
        // } else {
        //   window.location.href = referrer + '?sid=' + sid;
        // }
      } else {
        window.location.href = 'https://www.mcd.cn/'; // ?sid=' + sid;
      }
    } else {
      Toast({
        type: 'msg',  // 类型
        msg: res.message, // 文字内容
        duration: 2000 // 显示时间
      })
    }
  }

  // 登陆按钮逻辑
  const loginBon = () => {
    if (!agreeTerm) {
      Toast({
        type: 'msg',  // 类型
        msg: '请阅读并同意条款并继续', // 文字内容
        duration: 2000 // 显示时间
      })
      return;
    }

    if (Cookies.get('tpaId')) { // 当有tapId时
      api.giftBind({
        appleCode: '',
        citicRegister: false,
        code: aesEncrypt(verCode.replace(/\s/g, '')),
        tel: aesEncrypt(phone.replace(/\s/g, '')),
        tpaId: Cookies.get('tpaId')
      }).then((res: any) => {
        loginSuccess(res);
      })
    } else { // 正常登陆流程
      api.login({
        citicRegister: agreeZXTerm, // 是否联合注册中信会员
        code: aesEncrypt(verCode.replace(/\s/g, '')),
        tel: aesEncrypt(phone.replace(/\s/g, ''))
      }).then((res: any) => {
        loginSuccess(res);
      })
    }
  }

  /**
   * 错误文本展示逻辑
   * @param message 提示文案
   * @param verCode 验证码 
   * @param type {number} 0：手机号文本 1：验证码错误文本
   */
  const triggerError = (type: number, message: string, verCode: string) => {
    let verCodeClassText = '';
    if (!type) {
      setMobileError(message);
    } else {
      setCodeError(message);
    }
    if (message) {
      if (!type) {
        setInputTopErrorClass('mobile-input-box__bottom');
      } else {
        setInputBottomErrorClass('mobile-input-box__bottom');
      }
    } else {
      if (!type) {
        setInputTopErrorClass('');
        verCodeClassText = 'button-yan__get';
        setVerCodeClass(verCodeClassText);
        setMobileSuffix(<McDIcon type="icontick" style={iconFontStyle}></McDIcon>);
      } else {
        setInputBottomErrorClass('');
      }
    }
    if (verCode && verCode.length === 6 && phoneFlag) {
      setEnabledVCActionClass('btnEnabled');
    } else {
      setEnabledVCActionClass(null);
    }
  }

  useEffect(() => {
    window.addEventListener('resize', () => {
      const height = document.documentElement.clientHeight || document.body.clientHeight;
      if (height < windowHeight) {
        setKeyboard(false);
      } else {
        setKeyboard(true);
      }
    })
  }, [])

  return (
    <div className="modal-login">

      {/* header */}
      <div className="header">
        <div className="logo"><img alt="logo" src={logoImg}></img></div>
        <h3>欢迎登录麦当劳</h3>
      </div>

      {/* body */}
      <div className="body">
        <div className="mobile-input-box">
          <div className={inputErrorTopClass}>
            <Input
              autoFocus={false}
              bordered={false}
              type="tel"
              placeholder="请输入手机号"
              prefix={<McDIcon type="iconmobile" style={iconFontStyle}></McDIcon>}
              suffix={mobileSuffix}
              onFocus={() => {
                setKeyboard(false);
              }}
              onBlur={() => {
                setKeyboard(true);
              }}
              maxLength={13}
              value={phone}

              onChange={(e: any) => {
                setMobileSuffix(null);
                let phone: string = e.target.value;

                let mobile = phone.replace(/\s/g, '');
                mobile = mobile.slice(0, 11)

                if (mobile.length > 0 && !mobile.match(/^\d+$/g)) {
                  phoneFlag = false;
                  setVerCodeClass('');
                  triggerError(0, '手机号必须为数字', verCode)
                  return;
                }
                if (mobile.length === 11) {
                  if (mobile.match(/^1[0-9]{10}$/) && !flag) {
                    phoneFlag = true;
                    setVerCodeClass('button-yan__get');
                    triggerError(0, '', verCode);
                  } else {
                    phoneFlag = false;
                    setVerCodeClass('');
                    triggerError(0, '请输入合法的手机号码', verCode)
                  }
                } else {
                  phoneFlag = false;
                  setVerCodeClass('');
                  triggerError(0, '请输入正确的手机号码', verCode);
                }
                const newphone = [mobile.slice(0, 3), mobile.slice(3, 7), mobile.slice(7)].join(' ').trim();
                let gap: number = newphone.length - phone.length;
                setPhone(newphone);
              }}
            />
            <div className="input-error">{mobileError}</div>
          </div>
          <div className={'input-box ' + inputErrorBottomClass}>
            <Input
              autoFocus={false}
              bordered={false}
              type="number"
              placeholder="请输入验证码"
              prefix={<SendIcon />}
              maxLength={13}
              value={verCode}
              onFocus={() => {
                setKeyboard(false);
              }}
              onBlur={() => {
                setKeyboard(true);
              }}

              onChange={(e: any) => {
                let verCode: string = e.target.value.slice(0, 6);
                setVerCode(verCode);
                if (!verCode) {
                  triggerError(1, '请输入验证码', verCode);
                } else {
                  triggerError(1, '', verCode);
                }
              }}
            />
            <Button
              className={'button-yan ' + verCodeClass}
              onClick={(e: any) => {
                const className = e.target.parentNode.className;
                if (className.indexOf('button-yan__get') !== -1) {
                  api.send({
                    type: 1,
                    tel: aesEncrypt(phone.replace(/\s/g, ''))
                  }).then((res: any) => {
                    if (res.success) {
                      setFlag(true);
                      Toast({
                        type: 'msg',  // 类型
                        msg: '验证码已发送，请注意查收!', // 文字内容
                        duration: 2000 // 显示时间
                      })
                      // 当手机号码输入正确后，点击调用获取验证码接口
                      setVerCodeClass('');
                      let num = 59;
                      const timer = setInterval(() => {
                        if (num === -1) {
                          setVerCodeClass('button-yan__get');
                          setVerCodeText(`重新获取`);
                          setFlag(false);
                          clearInterval(timer);
                        } else {
                          setVerCodeText(`重新获取(${num}s)`);
                          num -= 1;
                        }
                      }, 1000);
                    } else {
                      Toast({
                        type: 'msg',  // 类型
                        msg: res.message, // 文字内容
                        duration: 2000 // 显示时间
                      })
                    }
                  })
                }
              }}
            >{verCodeText}</Button>
          </div>
          <div className="input-error">{codeError}</div>
        </div>
        <div className="button-box">
          <Button
            onClick={loginBon}
            disabled={enabledVCActionClass ? false : true}
            className={enabledVCActionClass}
          >登录</Button>
        </div>
      </div>

      {/* footer */}
      {keyboard && <div className="footer">
        <div className="term-row term-row-1" onClick={(e) => {
          setAgreeTerm(!agreeTerm);
        }}>
          <div>
            <img className="check-icon" alt="" src={notTickImg} style={{ display: agreeTerm ? 'none' : 'block' }} />
            <img className="check-icon" alt="" src={tickImg} style={{ display: agreeTerm ? 'block' : 'none' }} />
          </div>
          <div>
            <label>我已经阅读并同意</label>
            <a onClick={(e) => { e.stopPropagation(); }} href="https://www.mcdonalds.com.cn/page/cma-terms-conditions"><span>《使用条款》</span></a>和
            <a onClick={(e) => { e.stopPropagation(); }} href="https://www.mcdonalds.com.cn/page/Privacy-Policy"><span>《麦当劳隐私权政策》</span></a>
          </div>
        </div>
        <div className="term-row" onClick={() => {
          setAgreeZXTerm(!agreeZXTerm);
        }}>
          <div>
            <img className="check-icon" alt="" src={notTickImg} style={{ display: agreeZXTerm ? 'none' : 'block' }} />
            <img className="check-icon" alt="" src={tickImg} style={{ display: agreeZXTerm ? 'block' : 'none' }} />
          </div>
          <div>
            <label>加入中信优享+，免费领取更多会员礼遇！我已阅读并同意</label>
            <a onClick={(e) => { e.stopPropagation(); }} href="https://tech.c.citic/css/html/agreement.html?hideBtn=true"><span>《中信优享+用户服务协议》</span></a>
          </div>
        </div>
      </div>}

    </div>
  )
});
