import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from "react-dom";
import { message, Modal } from 'antd';
import common from '@omc/common';
import Cookies from 'js-cookie';
import tool from '@/common/utils/tool';
import apis from '@/common/net/apis'
import '@/assets/styles/Aks.less';
import 'antd/dist/antd.less';

window.mcd = window.mcd || {};
// const sid = Cookies.get('sid');

function Index() {
  const [ruleVisible, setRuleVisible]: any = useState(false)
  const [showAll, setShowAll]: any = useState(true)
  const [canDraw, setCanDraw]: any = useState(true)
  const hasDrawed = useRef(false)
  const btnDisabled = useRef(false)
  const activityCode = useRef('')

  const [pageConfig, setPageConfig]: any = useState({
    bgImage: 'https://cdn-test.mcdchina.net/gallery/ecfe4fe955280e07.jpg',
    btnImage: 'https://cdn-test.mcdchina.net/gallery/c52297bc3da6f0c6.gif',
    useTips: '',
    activityRule: '',
    prizeName: ''
  })

  useEffect(() => {
    window.document.title = '麦当劳苏州社群专享'
    window.location.search.slice(1).split('&').filter((i: any) => i.includes('activityCode')).map((v: any) => {
      let keyMap = v.split('=');
      activityCode.current = keyMap?.[1]
    })
  }, [])

  useEffect(() => {
    //查询活动接口，判断活动是否已结束或不能参与
    apis.getActivityStatus(activityCode.current).then((res: any) => {
      let tempPageConfig = {...pageConfig}
      if (res && res?.success && res?.data) {
        let data = res.data
        let activityInfo = data?.activityInfo || {}
        tempPageConfig.activityRule = activityInfo?.ruleDesc
        if(activityInfo?.activityStatus == 0){ 
          message.error('来早啦，活动尚未开始')
          setCanDraw(false)
          setPageConfig({...tempPageConfig})
          return
        } else if(activityInfo?.activityStatus == 2) {
          message.error('很抱歉，活动已结束')
          setPageConfig({...tempPageConfig})
          setCanDraw(false)
          //重设背景图
          setShowAll(false)
          return
        }
        if (data?.lotteryFlag == 0) { //没抽过奖
          tempPageConfig.bgImage = activityInfo?.coverImg
        } else {
          hasDrawed.current = true
          if (!!data?.rewardInfo) { //只有抽过奖才会返回这个对象
            tempPageConfig.btnImage = 'https://cdn-test.mcdchina.net/gallery/e4f833a3c00143aa.png'
            tempPageConfig.bgImage = data?.rewardInfo?.prizePic
            tempPageConfig.prizeName = data?.rewardInfo?.prizeName
            tempPageConfig.useTips = data?.rewardInfo?.tradeEndTimeText
          }
        }
        setPageConfig({...tempPageConfig})
      } else if (!window.navigator.onLine) {
        message.error('网络开小差了，请检查网络')
        return
      } else {
        message.error('系统开小差了，请稍后再试')
        return
      }
    })
  }, [])

  const checkLogin = () => {
    if(Cookies.get('sid')) {
      if(!btnDisabled.current) {
        drawForPrize() //登陆成功调用抽奖
        setTimeout(() => {
          btnDisabled.current = false
        }, 500) //防抖
      }
      btnDisabled.current = true
    } else {
      window.location.href = './login'
    }
  }

  const getUtmData = (utmString: string) => {
    let value = window.location.search.slice(1).split('&').filter((i: any) => i.includes(utmString)).map((v: any) => {
      let b = v.replaceAll(`${utmString}=`, '')
      return b
    }) || ''
    return value[0] || ''
  }

  const drawForPrize = () => {
    if(!canDraw) { //活动异常
      return
    }
    if (hasDrawed.current) {
      // 调用短链接口
      let jumpParams = {
        campaign: getUtmData('utm_campaign'),
        content: getUtmData('utm_content'),
        medium: getUtmData('utm_medium'),
        source: getUtmData('utm_source'),
        term: getUtmData('utm_term'),
        type: 2
      }
      apis.jumpToApp(jumpParams).then((res : any) => {
        if(!!res && res.success && res.data) {
          window.location.href = res.data.shortUrl
        } else if (!window.navigator.onLine) {
          message.error('网络开小差了，请检查网络')
          return
        } else {
          message.error('系统开小差了，请稍后再试')
          return
        }
      })
      return
    }
    let params = {
      activityCode: activityCode.current,
      style: 1
    }
    apis.drawForPrize(params).then((res: any) => {
      let tempPageConfig = {...pageConfig}
      if (res && res.success && res.data) { //调用成功
        if(res?.data?.status == 0) { //抽奖成功
          hasDrawed.current = true
          let rewardSendInfo = res.data?.rewardSendInfo
          tempPageConfig.bgImage = rewardSendInfo?.prizePic
          tempPageConfig.prizeName = rewardSendInfo?.prizeName
          tempPageConfig.useTips = rewardSendInfo?.tradeEndTimeText || ''
          tempPageConfig.btnImage = 'https://cdn-test.mcdchina.net/gallery/e4f833a3c00143aa.png'
          setPageConfig({...tempPageConfig})
        } else if(res?.data?.status == 1) { //库存不足
          setCanDraw(false) 
          message.error('来晚啦，本期奖品已抽完')
          return
        } else {
          message.error('系统开小差了，请稍后再试')
          return
        }
      } else if(!!res && !res.success && res.code == 401) { // 未登陆
        Cookies.remove('sid')
        window.location.href = './login'
      } else if (!window.navigator.onLine) {
        message.error('网络开小差了，请检查网络')
        return
      } else {
        message.error('系统开小差了，请稍后再试')
        return
      }
    })
  }

  return (
    <div className='aks-root' style={{ 
      backgroundImage: showAll ? `url(${pageConfig.bgImage})` : '',
      display: showAll ? '' : 'flex',
    }}>
      {!showAll && <div className='aks-over-wrap'>
        <img className='aks-over-img' src='https://cdn-test.mcdchina.net/gallery/fecfbd7c25e38508.png' />
        <div className='aks-over-title'>您来晚啦，活动已经结束啦～</div>
        <div className='aks-over-subtitle'>下次早点来哦～</div>
      </div>}
      {showAll && <div>
      <div className='aks-action-container' style={{ top: hasDrawed.current ? '74.5%' : '70.3%'}}>
        <img src={pageConfig.btnImage} className='aks-join-activity' style={{
          width: hasDrawed.current ? '230PX' : '261px',
          height: hasDrawed.current ? '48px' : '70px'
        }} onClick={checkLogin} />
      </div>
        <div className='aks-prize-text-container'>
        <div className='aks-prize-name'>{pageConfig.prizeName}</div>
        <div className='aks-use-tips'>{pageConfig.useTips}</div>
      </div>
      <img src='https://cdn-test.mcdchina.net/gallery/a6547d33c1ecf206.png' className='aks-activity-rule' onClick={() => setRuleVisible(true)} />
      <Modal
        open={ruleVisible}
        closable={false}
        footer={null}
        className='aks-modal'
        width={'calc(100vw - 50PX)'}
        maskStyle={{backgroundColor: 'rgba(0, 0, 0, 0.7)'}}
        getContainer={false}
      >
        <div className='aks-body-container' onScroll={(e) => {e.stopPropagation()}}>
          <div className='aks-modal-container aks-modal-contant'>{pageConfig.activityRule}</div>
          <div className='aks-modal-container aks-modal-footer'>
            <div className='aks-modal-footer-action' onClick={() => setRuleVisible(false)}>知道了</div>
          </div>
        </div>
      </Modal>
      </div>}
    </div>
  )
}
export default Index



