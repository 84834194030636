const Cookies = require("js-cookie");
let envConfig = {};

let apiBaseUrl = "";
if (typeof window !== "undefined") {
  let queryHost = Cookies.get("queryHost");
  let search = window.location.search;
  if (search) {
    search
      .slice(1)
      .split("&")
      .filter((i) => i.includes("envH"))
      .map((v) => {
        let keyMap = v.split("=");
        if (keyMap[0] === "envH") {
          queryHost = keyMap[1];
        }
      });
  }
  const legalHost = {
    'd1': 'https://api-dev.mcdchina.net',
    's1': 'https://api-sit.mcdchina.net',
    'u1': 'https://api-uat.mcdchina.net',
    's2': 'https://ali-sit.mcdchina.net',
    'u2': 'https://api2-uat.mcdchina.net',
    'p1': 'https://api-pt.mcdchina.net',
    'p2': 'https://api2-pt.mcdchina.net',
    'p3': 'https://api-pt.mcd.cn',
    'p4': 'https://api2-pt.mcd.cn',
    't1': 'https://api-test.mcdchina.net',
    'st1': 'https://api-stg.mcdchina.net',
    'r1': 'https://api.mcd.cn',
    'r2': 'https://api2.mcd.cn',
  };
  if (queryHost && legalHost[queryHost]) {
    Cookies.set("queryHost", queryHost, { expires: 2 });
    apiBaseUrl = legalHost[queryHost];
  } else {
    Cookies.set("queryHost", "");
  }
}
console.log("apiBaseUrl", apiBaseUrl);
if (process.env.REACT_APP_BRANCH) {
  envConfig = require(`./config.${process.env.REACT_APP_BRANCH}.js`);
} else {
  if (typeof window !== "undefined") {
    const hostname = window.location.hostname;
    switch (hostname) {
      case "localhost":
      case "127.0.0.1":
      case "10.126.21.194":
      case "10.126.22.177":
      case "10.126.23.161":
      case "local.m.net":
      case "m-sit.mcdchina.net":
      case "local.mcdchina.net":
        console.log("sit");
        envConfig = require("./config.sit");
        break;
      case "m-dev.mcdchina.net":
      case "mcdchina.net":
        envConfig = require("./config.develop");
        break;
      case "m-test.mcdchina.net":
        envConfig = require("./config.test");
        break;
      case "m-stg.mcdchina.net":
        envConfig = require("./config.stage");
        break;
      case "m-uat.mcdchina.net":
        envConfig = require("./config.uat");
        break;
      default:
        envConfig = require("./config.master");
    }
  } else {
    envConfig = require(`./config.develop.js`);
  }
}
envConfig.API_BFF_URL = apiBaseUrl || envConfig.API_BFF_URL;
module.exports = envConfig;
