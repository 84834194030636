import React, {useEffect} from 'react'
import * as common from '@/common/common';

export default () => {
  useEffect(() => {
    document.title = '麦当劳';
    window.location.replace('https://cdn.mcd.cn/cms/pages/giftcardbind.html');
  })
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh'
    }}>
      <img
        style={{'width': '50%'}}
        src={common.CDN_URLS.loadingGif} />
    </div>
  )
}