const configObj: any = require('./config-cjs.js');

interface ConfigObject {
  API_BFF_URL: string;
  CDN_PROJ_BASE_URL: string;
  PARTNER: {
    CCB_OAUTH_URL: string;
    CCB_CLIENT_ID: string;
    CCB_RESPONSE_TYPE: string;
    CCB_REDIRECT_URI: string;
    CCB_MERCHANT_ID: string;
  };
  AGENT_ID: string;
}
export default configObj as ConfigObject;
