import React,{useEffect, useState} from 'react';
import '@/assets/styles/redpacket/index.less';

const selectedIcon = 'https://img.mcd.cn/mini/main/images/login_checked.png' // 勾选icon
const unSelectedIcon = 'https://img.mcd.cn/mini/main/images/login_check.png' // 未勾选icon

export default (props: any) => {
    const {
        storeList,
        selectedCode,
        currentClickStore
    } = props;
  
  const [currentCode, setCurrentCode] = useState(selectedCode || '');

  return (
    <div className="store-list-container">
        {storeList.map((item: any) => 
            <div className='store-item-wrap' key={item?.storeCode} onClick={()=> {
                setCurrentCode(item?.storeCode || '');
                currentClickStore(item);
            }}>
                <img className='store-select-icon' src={currentCode==item?.storeCode ? selectedIcon : unSelectedIcon}/>
                <img className='store-image' src={item?.storeImg} />
                <div className='store-name'>{item?.storeName}</div>
                <div className='store-code'>{'国际编码：' + item?.storeCode}</div>
                <div className='store-time'>{item?.openTime?.replace(/-/g, ".")+'开业'}</div>
            </div>
        )}
    </div>)
}