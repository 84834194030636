module.exports = {
  API_BFF_URL: 'https://api.mcd.cn',
  CDN_PROJ_BASE_URL: "https://cdn.mcd.cn/m/",
  PARTNER: {
    CCB_OAUTH_URL: 'https://creditcardapp.bankcomm.com/oautht/authorize',
    CCB_CLIENT_ID: 'mdb_to_mcd',
    CCB_RESPONSE_TYPE: 'code',
    CCB_REDIRECT_URI: 'https://m.mcd.cn/partner/login',
    CCB_MERCHANT_ID: 'M75374576',
  },
  AGENT_ID: '1000086'
}