module.exports = {
  "nested": {
    "stat": {
      "options": {
        "java_package": "appcatch.protobuf",
        "java_outer_classname": "Allinfos"
      },
      "nested": {
        "Allinfos": {
          "fields": {
            "mDeviceInfo": {
              "type": "DeviceInfo",
              "id": 1
            },
            "mLocationInfo": {
              "type": "LocationInfo",
              "id": 2
            },
            "mNetInterfaceInfo": {
              "rule": "repeated",
              "type": "NetInterfaceInfo",
              "id": 3
            },
            "mPageTimeInfo": {
              "rule": "repeated",
              "type": "PageTimeInfo",
              "id": 4
            },
            "mCurlNetworkInfo": {
              "rule": "repeated",
              "type": "CurlNetworkInfo",
              "id": 5
            },
            "mH5ErrorInfo": {
              "rule": "repeated",
              "type": "H5ErrorInfo",
              "id": 6
            },
            "mEventInfo": {
              "rule": "repeated",
              "type": "EventInfo",
              "id": 7
            },
            "mNetDiagnosis": {
              "rule": "repeated",
              "type": "NetDiagnosis",
              "id": 8
            },
            "mUserInfo": {
              "type": "UserInfo",
              "id": 9
            }
          }
        },
        "DeviceInfo": {
          "fields": {
            "mDevicetoken": {
              "type": "string",
              "id": 1
            },
            "mChannel": {
              "type": "string",
              "id": 2
            },
            "mClientType": {
              "type": "int32",
              "id": 3
            },
            "mDeviceManu": {
              "type": "string",
              "id": 4
            },
            "mDeviceCpu": {
              "type": "string",
              "id": 5
            },
            "mIsRoot": {
              "type": "bool",
              "id": 6
            },
            "mSysVersion": {
              "type": "string",
              "id": 7
            },
            "mAppVersion": {
              "type": "string",
              "id": 8
            },
            "mDeviceSupportCpus": {
              "type": "string",
              "id": 9
            }
          }
        },
        "LocationInfo": {
          "fields": {
            "mLat": {
              "type": "double",
              "id": 1
            },
            "mLon": {
              "type": "double",
              "id": 2
            },
            "mCity": {
              "type": "int32",
              "id": 3
            },
            "mCityName": {
              "type": "string",
              "id": 4
            },
            "mCountry": {
              "type": "string",
              "id": 5
            }
          }
        },
        "NetInterfaceInfo": {
          "fields": {
            "mTime": {
              "type": "int64",
              "id": 1
            },
            "mProType": {
              "type": "int32",
              "id": 2
            },
            "mHostName": {
              "type": "string",
              "id": 3
            },
            "mPath": {
              "type": "string",
              "id": 4
            },
            "mRequestType": {
              "type": "int32",
              "id": 5
            },
            "mNetType": {
              "type": "int32",
              "id": 6
            },
            "mTimeConsum": {
              "type": "int64",
              "id": 7
            },
            "mStatusCode": {
              "type": "int32",
              "id": 8
            },
            "mProPath": {
              "type": "string",
              "id": 9
            },
            "mReqDataSize": {
              "type": "int64",
              "id": 10
            },
            "mResDataSize": {
              "type": "int64",
              "id": 11
            },
            "mErrorRequest": {
              "type": "string",
              "id": 12
            },
            "mErrorResponse": {
              "type": "string",
              "id": 13
            },
            "mDNSUse": {
              "type": "int32",
              "id": 14
            },
            "mInterfaceDNSUse": {
              "type": "int32",
              "id": 15
            },
            "mDNSSuccess": {
              "type": "int32",
              "id": 16
            },
            "mServerIp": {
              "type": "string",
              "id": 17
            },
            "mCarrier": {
              "type": "string",
              "id": 18
            },
            "mSid": {
              "type": "string",
              "id": 19
            },
            "mHeaderInfo": {
              "type": "string",
              "id": 20
            },
            "mNetChange": {
              "type": "int32",
              "id": 21
            },
            "mReConnection": {
              "type": "int32",
              "id": 22
            },
            "mClientIp": {
              "type": "string",
              "id": 31
            },
            "mNameLookUpTime": {
              "type": "double",
              "id": 32
            },
            "mConnectTime": {
              "type": "double",
              "id": 33
            },
            "mSslHandshakeTime": {
              "type": "double",
              "id": 34
            },
            "mPreTransferTime": {
              "type": "double",
              "id": 35
            },
            "mStartTransferTime": {
              "type": "double",
              "id": 36
            },
            "mReqParams": {
              "type": "string",
              "id": 37
            }
          }
        },
        "PageTimeInfo": {
          "fields": {
            "mPageId": {
              "type": "string",
              "id": 1
            },
            "mPageName": {
              "type": "string",
              "id": 2
            },
            "mStartTime": {
              "type": "int64",
              "id": 3
            },
            "mSubProcess": {
              "type": "string",
              "id": 4
            },
            "mTimeConsum": {
              "type": "int64",
              "id": 5
            },
            "mNetType": {
              "type": "int32",
              "id": 6
            }
          }
        },
        "CurlNetworkInfo": {
          "fields": {
            "mTime": {
              "type": "int64",
              "id": 1
            },
            "mPath": {
              "type": "string",
              "id": 2
            },
            "mNetType": {
              "type": "int32",
              "id": 3
            },
            "mNameLookUpTime": {
              "type": "double",
              "id": 4
            },
            "mConnectTime": {
              "type": "double",
              "id": 5
            },
            "mSslHandshakeTime": {
              "type": "double",
              "id": 6
            },
            "mPreTransferTime": {
              "type": "double",
              "id": 7
            },
            "mStartTransferTime": {
              "type": "double",
              "id": 8
            },
            "mRedirectTime": {
              "type": "double",
              "id": 9
            },
            "mRedirectCount": {
              "type": "int64",
              "id": 10
            },
            "mTotalTime": {
              "type": "double",
              "id": 11
            },
            "mServerIp": {
              "type": "string",
              "id": 12
            },
            "mDataSize": {
              "type": "int32",
              "id": 13
            },
            "mCode": {
              "type": "int32",
              "id": 14
            },
            "mCarrier": {
              "type": "string",
              "id": 15
            },
            "mHttpCode": {
              "type": "int32",
              "id": 16
            },
            "mClientIp": {
              "type": "string",
              "id": 31
            }
          }
        },
        "H5ErrorInfo": {
          "fields": {
            "mStatus": {
              "type": "int32",
              "id": 1
            },
            "mErrorCode": {
              "type": "int32",
              "id": 2
            },
            "mErrorDesc": {
              "type": "string",
              "id": 3
            },
            "mErrorUrl": {
              "type": "string",
              "id": 4
            },
            "mNetType": {
              "type": "int32",
              "id": 5
            },
            "mCarrier": {
              "type": "string",
              "id": 6
            },
            "mTime": {
              "type": "int64",
              "id": 7
            },
            "mServerIp": {
              "type": "string",
              "id": 8
            }
          }
        },
        "EventInfo": {
          "fields": {
            "mEventName": {
              "type": "string",
              "id": 1
            },
            "mTime": {
              "type": "int64",
              "id": 2
            },
            "mEvents": {
              "type": "string",
              "id": 3
            }
          }
        },
        "NetDiagnosis": {
          "fields": {
            "mCurlNetworkInfo": {
              "type": "CurlNetworkInfo",
              "id": 1
            },
            "mResponseHeader": {
              "type": "string",
              "id": 2
            },
            "mSignalStrength": {
              "type": "string",
              "id": 3
            },
            "mLocalDNS": {
              "type": "string",
              "id": 4
            },
            "mLocalDNSOutIP": {
              "type": "string",
              "id": 5
            },
            "mNetSpeed": {
              "type": "string",
              "id": 6
            },
            "mTraceroute": {
              "type": "string",
              "id": 7
            },
            "mDiagnosisType": {
              "type": "int32",
              "id": 8
            }
          }
        },
        "UserInfo": {
          "fields": {
            "openId": {
              "type": "string",
              "id": 1
            },
            "meddyId": {
              "type": "string",
              "id": 2
            }
          }
        }
      }
    }
  }
}