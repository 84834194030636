/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from 'react';
import '@/assets/styles/store-tool.less';
import apis from '@/common/net/apis';

export default () => {
  const deviceRatio = 1
  // const deviceRatio = window.devicePixelRatio;
  const [usCode, setUsCode] = useState('')
  const [showToast, setShowToast] = useState('');
  const [showSave, setShowSave] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const img: any = document.querySelector('#J_image');
  const drawAllImages = (ctx: any, qrImage: any, usCode: any) => {
    const p1: any = drawImage(ctx, qrImage, 10, 10, 330, 330, usCode);
    return Promise.all([p1])
  }
  function drawImage(ctx: any, qrImage: any, w0: any, h0: any, w1: any, h1: any, usCode: any) {
    return new Promise(function (resolve, reject) {
      const img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');
      img.onload = function () {
        ctx.drawImage(img, 25 * deviceRatio, 25 * deviceRatio, 1000 * deviceRatio, 1000 * deviceRatio);
        img.style.display = 'none';
        resolve(img);
      }
      img.onerror = function () {
        reject();
      }
      img.src = qrImage;
    })
  }
  //方法一
  // const [QrSvg, setQrSvg] = useState(<div></div>)
  // useEffect(() => {
  //   let str: any = document.getElementById('qrSvgElement')?.innerHTML;
  //   const qrsvgCode = `data:image/svg+xml;base64,` + Base64.encode(str);
  //   //绘制canvas开始
  //   const canvas: any = document.getElementById('canvas');
  //   canvas.width = canvas.clientWidth * deviceRatio;
  //   canvas.height = canvas.clientHeight * deviceRatio;
  //   const ctx = canvas.getContext('2d');
  //   (async () => {
  //     ctx.fillStyle = '#fff';
  //     ctx.fillRect(0, 0, 350 * deviceRatio, 350 * deviceRatio);
  //     drawAllImages(ctx, qrsvgCode);
  //     ctx.fillStyle = '#000';
  //     ctx.textAlign = 'right';
  //     ctx.font = "60px Microsoft YaHei";
  //     ctx.fillText(usCode, 295 * deviceRatio, 315 * deviceRatio);
  //     const toImage = canvas.toDataURL('image/png', 1);
  //     const img = document.createElement('img');
  //     img.src = toImage
  //   })()
  // }, [QrSvg])
  // }
  //方法二
  const generateQr = () => {
    return new Promise((resolve, reject) => {
      const pathname = /^199|^333/.test(usCode) ? 'q' : 's';
      const params = {
        jumpUrl: `https://qr.mcd.cn/${pathname}?utm_term=${usCode}`,
      }
      apis.generateQr(params).then((res: any) => {
        res && res.data && resolve(res.data.encodeStr)
      }, (e: any) => {
        reject();
      })
    })
  }
  const createQr = () => {
    if (isLoading) {
      return
    }

    if (!/^\d{7,15}$/.test(usCode)) {
      setShowToast('请输入正确的us_code（7-15位纯数字）');
      setTimeout(() => {
        setShowToast('');
      }, 1500)
      return
    }
    setLoading(true);
    (async () => {
      let qrImage = await generateQr();
      if (!qrImage) {
        setShowToast('生成失败，请重试');
        setTimeout(() => {
          setShowToast('');
        }, 1500)
      }
      let canvas: any = document.getElementById('canvas');
      canvas.style.display = 'block'
      canvas.width = canvas.clientWidth * deviceRatio;
      canvas.height = canvas.clientHeight * deviceRatio;
      const ctx = canvas.getContext('2d');
      ctx.fillStyle = '#fff';
      ctx.fillRect(0, 0, 1050 * deviceRatio, 1050 * deviceRatio);
      await drawAllImages(ctx, qrImage, usCode);
      ctx.fillStyle = '#fff';
      ctx.fillRect(650 * deviceRatio, 980 * deviceRatio, 340 * deviceRatio, 60 * deviceRatio);
      ctx.fillStyle = '#000';
      ctx.textAlign = 'right';
      ctx.font = "500 60px Speedee";

      ctx.fillText(usCode, 975 * deviceRatio, 1035 * deviceRatio);
      canvas.style.display = 'none'
      const toImageUrl = canvas.toDataURL('image/png', 1);

      img.style.display = 'block';
      img.crossOrigin = "anonymous";
      img.src = toImageUrl;
      setLoading(false);
      setShowSave(true)
    })()
  }
  const QrType = () => {
    if (/^199|^333/.test(usCode)) {
      return (<div className="qr-type">轻店版<span className="text">（限江浙地区，199、333开头）</span></div>)
    } else {
      return (<div className="qr-type">常规版</div>)
    }
  }
  return (
    <div className="store-tool-wrapper">
      <div className="title">门店二维码生成工具</div>
      <div className="input-code">
        <input type="number"
          pattern="\d*"
          placeholder="请输入餐厅 us_code"
          value={usCode}
          onChange={e => {
            setUsCode(e.target.value)
            setShowSave(false)
            img && (img.style.display = 'none');
          }} />
        {usCode && <span className="clear" onClick={() => {
          setUsCode('')
          setShowSave(false)
          img.style.display = 'none';
        }}>x</span>}
      </div>
      <div className="create-qr" onClick={createQr} style={{ opacity: isLoading ? '0.7' : 1 }}>{isLoading ? '二维码生成中...' : '生成二维码'}</div>
      <canvas id="canvas" style={{ display: 'none' }} width={1050 * deviceRatio} height={1050 * deviceRatio}></canvas>
      {showSave && <QrType ></QrType>}
      <img id="J_image" className="qr-image" crossOrigin="anonymous" />
      {showSave && <div className="save-image">请长按二维码图片保存</div>}
      {
        showToast && <div className="toast">
          <span className="point">!</span>{showToast}
        </div>
      }
      <div className={showSave ? 'help' : 'fix-help'}>
        <p className="question" onClick={() => setShowEmail(!showEmail)}>故障问题</p>
        {showEmail && <p className="email">如生成失败，请邮件联系gina.xu@cn.mcd.com</p>}
      </div>
    </div>
  )
}