// import loginutil from '@/src/login/pages/loginUtils';

enum CTLists {
  WEAPP = '11',
}

const globalData: any = {
  ct: '11',
  language: 'zh',
  loginType: 0,// 微信登录类型 0=i麦当劳小程序
  version: '1.5.0.0',
  contentViewHeight: 0, //自定义头部高度
  navBarBottom: 64, //头部导航栏底部
  locationContentHeight: 71, //定位卡片的高度
  isIphone8:undefined,
  newUserGift: false, //是否是新用户（24小时内注册的，展示新人有礼用）
  getCt() {
    return '11';
  },
  changeCt(data:any) {
    this.ct = data || 11;
  },
  getLanguage() {
    return this.language;
  },
  changeLanguage(data:any) {
    this.language = data || 'zh';
  },
  getLoginType() {
    return this.loginType;
  },
  changeLoginType(data:any) {
    this.loginType = data || 0;
  },
  getVersion() {
    return this.version;
  },
  changeVersion(data:any) {
    this.version = data || '2.0.9.0';
  },
  setContentViewHeight(height:any) {
    this.contentViewHeight = height;
  },
  getContentViewHeight(height:any) {
    return this.contentViewHeight;
  },

  netInterfaceLists: [],
  eventInfoLists: [],
  locationCityName: ''
}

export default globalData;

