import Cookies from 'js-cookie';
import apis from '@/common/net/apis';

const getSearchByKey = (key: any) => {
  let keyValue;
  let search = window.location.search;
  if(!search || !key) return;

  search.slice(1).split('&').filter(i => i.includes(key)).map(v => {
    let keyMap = v.split('=');
    if(keyMap[0] === key) {
      keyValue = keyMap[1]
    }
  })

  return keyValue;
}

const wechatOauth = () => {
  const APP_ID = 'wx42cf5e73876fe3b9';
  let redirect_uri = encodeURIComponent(window.location.href);
  let href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APP_ID}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`
  window.location.href = href;
}

// const genWsidByCode = async (code: any) => {
//   const res :any = await apis.wecomLogin({ code });
//   let sid: any = res?.data?.wsid
//   if (sid) {
//     Cookies.set('wsid', res.data.wsid, { expires: 15 })
//   }
//   return sid;
// }

export default {
  genMCDSid: async () => {
    let sid: any = Cookies.get('wsid');
    if (!sid) {
      const code = getSearchByKey('code');
      if (!code) { //没有code去授权
        wechatOauth();
      } else { // 有code换取wsid
        const res :any = await apis.wecomLogin({ code });
        sid = res?.data?.wsid
      }
    } else {
        // const res :any = await apis.wecomLogin({ code });
        // sid = res?.data?.wsid
    }
    if (sid) {
      Cookies.set('wsid', sid, { expires: 15 })
    }
    return sid;
  }
}