import axios from 'axios';
import Cookies from 'js-cookie';
import globalData from "./globalData";
import {getNetWorkType} from './common';
import config from '@/common/config/config';
let protobuf = require('@/weichatPb/protobuf');
let protoJs = require('./performanceInfo');
var parser = require('ua-parser-js');

const statRoot = protobuf.Root.fromJSON(protoJs);

const ampHandler: any = {

  createBaseInfo: () => {
    let deviceInfo = statRoot.lookupType('stat.DeviceInfo');
    let locationInfo = statRoot.lookupType('stat.LocationInfo');
    let userInfo = statRoot.lookupType('stat.UserInfo');

    let deviceInfoParams = {} as any;
    let ua = parser(navigator.userAgent)
    console.log('ua', ua)
    const mChannel: number = ua.os.name.indexOf('ios') !== -1 ? 101 : 102
    deviceInfoParams.mChannel = mChannel.toString();
    deviceInfoParams.mClientType = mChannel;
    deviceInfoParams.mDeviceManu = ua.device.model;
    deviceInfoParams.mIsRoot = false;
    deviceInfoParams.mSysVersion = `${ua.os.name}  ${ua.os.version}`;
    deviceInfoParams.mAppVersion = globalData.version;
    let deviceInfoObj = deviceInfo.create(deviceInfoParams);

    let userInfoParams = {} as any;
    const sessionObjKey =Cookies.get('wsid') || Cookies.get('sid') || '';
    let sid = "";
    if(!sessionObjKey) {
      sid = "";
    } else {
      sid = sessionObjKey;
    }
    userInfoParams.meddyId = sid;
    let userInfoObj = userInfo.create(userInfoParams);

    let locationInfoParams = {} as any;
    const locationData:any = Cookies.get('locationData') || '';
    if(locationData !== ""){
      const locationDataObj:any = JSON.parse(locationData);
      locationInfoParams.mLat = locationDataObj.latitude;
      locationInfoParams.mLon = locationDataObj.longitude;
      locationInfoParams.mCityName = globalData.locationCityName;
    }
    let locationInfoObj = locationInfo.create(locationInfoParams);
    return {
      deviceInfoObj,
      locationInfoObj,
      userInfoObj
    }
  },

  uploadEventInfo: async (eventName: string, time: number, event: string) => {
    let allInfo = statRoot.lookupType('stat.Allinfos');
    let eventInfo = statRoot.lookupType('stat.EventInfo');
    let eventInfoParams = {} as any;
    eventInfoParams.mEventName = eventName;
    eventInfoParams.mTime = time;
    eventInfoParams.mEvents = event;
    let eventInfoObj: any = eventInfo.create(eventInfoParams);
    globalData.eventInfoLists.push(eventInfoObj);
    const {deviceInfoObj, userInfoObj}: any = ampHandler.createBaseInfo();
    let allInfoObj = allInfo.create({
      mDeviceInfo: deviceInfoObj,
      mUserInfo: userInfoObj,
      mEventInfo: globalData.eventInfoLists
    })
    let allInfoEncodeBuffer: any = allInfo.encode(allInfoObj).finish();
    let ampHost = config.API_BFF_URL;
    if (ampHost.indexOf('api.mcd.cn') == -1) {
      ampHost = 'https://api-dev.mcdchina.net'
    }


    axios({
      method: 'POST',
      url: `${ampHost}/bff/collector/collect/event`, //仅为示例，并非真实的接口地址
      data: allInfoEncodeBuffer.slice().buffer,
      responseType: 'arraybuffer',
      headers: {
        'ct': globalData.getCt(),
        'content-type': 'application/x-protobuf' // 默认值
      },
    }).then((res: any) => {
      console.log(res)
    })
    // Taro.request({
    //   url: `${ampHost}/bff/collector/collect/event`, //仅为示例，并非真实的接口地址
    //   data: allInfoEncodeBuffer.slice().buffer,
    //   method: "POST",
    //   header: {
    //     'ct': globalData.getCt(),
    //     'content-type': 'application/x-protobuf' // 默认值
    //   },
    //   dataType: 'byte',
    //   responseType: 'arraybuffer',
    //   success: function (res:any) {
    //     console.log(res);
    //   },
    //   fail: function (err:any) {
    //     console.log(err);
    //   }
    // })
    globalData.eventInfoLists = [];
  },

  uploadPerformanceInfo: async (requestParams: any, responseParams: any) => {
    const apiPath:any = {
      dev: 'api-dev.mcdchina.net', //20
      sit: 'api-sit.mcdchina.net', //20
      uat: 'api-uat.mcdchina.net', //20
      stg: 'api-stg.mcdchina.net', //20
      master: 'api.mcd.cn' //10
    }
    let mHostName = "";
    let mPath = "";
    for(let key in apiPath){
      const findIndex = requestParams.url.indexOf(apiPath[key]);
      if(findIndex >=0 ) {
        if(requestParams.url.indexOf('api.mcd.cn') >=0){
          mHostName = 'api.mcd.cn'
        } else {
          mHostName = apiPath[key]
        }
        mPath = requestParams.url.substring(8 + mHostName.length, requestParams.url.length)
      }
    }

    let allInfo = statRoot.lookupType('stat.Allinfos');
    let netInterfaceInfo = statRoot.lookupType('stat.NetInterfaceInfo');
    let netInterfaceInfoParams = {} as any;
    netInterfaceInfoParams.mTime = new Date().getTime();
    netInterfaceInfoParams.mProType = 2;
    netInterfaceInfoParams.mHostName = mHostName;
    netInterfaceInfoParams.mPath = mPath;
    netInterfaceInfoParams.mRequestType = requestParams.method;
    netInterfaceInfoParams.mNetType = await getNetWorkType() as any;
    // mTimeConsum
    netInterfaceInfoParams.mStatusCode = responseParams?.data?.code;
    netInterfaceInfoParams.mProPath = requestParams.url;
    netInterfaceInfoParams.mReqParams = JSON.stringify(requestParams?.data) || '{}';
    if(!responseParams?.data?.success) {
      netInterfaceInfoParams.mErrorResponse = JSON.stringify(responseParams.data) || '{}';
    }

    let netInterfaceObj = netInterfaceInfo.create(netInterfaceInfoParams);
    globalData.netInterfaceLists.push(netInterfaceObj);
    if (globalData.netInterfaceLists.length > 10) {
      const {deviceInfoObj, locationInfoObj, userInfoObj}: any = ampHandler.createBaseInfo();
      let allInfoObj = allInfo.create({
        mDeviceInfo: deviceInfoObj,
        mLocationInfo: JSON.stringify(locationInfoObj) === '{}' ? undefined:locationInfoObj,
        mUserInfo: userInfoObj,
        mNetInterfaceInfo: globalData.netInterfaceLists
      })

      let allInfoEncodeBuffer: any = allInfo.encode(allInfoObj).finish();
      let ampHost = "";
      if(requestParams.url.indexOf('api.mcd.cn') >=0){
        ampHost = 'api.mcd.cn'
      } else {
        ampHost = 'api-dev.mcdchina.net'
      }

      axios({
        method: 'POST',
        url:  `https://${ampHost}/bff/collector/collect/request`, //仅为示例，并非真实的接口地址
        data: allInfoEncodeBuffer.slice().buffer,
        responseType: 'arraybuffer',
        headers: {
          'ct': globalData.getCt(),
          'content-type': 'application/x-protobuf' // 默认值
        },
      }).then((res: any) => {
        console.log(res)
      })

      // Taro.request({
      //   url: `https://${ampHost}/bff/collector/collect/request`, //仅为示例，并非真实的接口地址
      //   data: allInfoEncodeBuffer.slice().buffer,
      //   method: "POST",
      //   header: {
      //     'ct': globalData.getCt(),
      //     'content-type': 'application/x-protobuf' // 默认值
      //   },
      //   dataType: 'byte',
      //   responseType: 'arraybuffer',
      //   success: function (res:any) {
      //     console.log(res);
      //   },
      //   fail: function (err:any) {
      //     console.log(err);
      //   }
      // })
      globalData.netInterfaceLists = [];
    }
  }
}

export default ampHandler;