import React,{useEffect, useState} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import '@/assets/styles/redpacket/index.less';
const EMPTY_IMG = 'https://img.mcd.cn/gallery/538020210128050155064.png';

export default (props: any) => {
  const { activityList, storeCode } = props;
  console.log('activityList',activityList)
  // const [list, setList] = useState(activityList)

  let history = useHistory();

  const getDate = (beginTime: any, endTime: any) => {
    if(beginTime && endTime) {
      const begin = beginTime.split('-');
      const end = endTime.split('-');
      if(beginTime == endTime) {
        return `${begin[1]}月${begin[2]}日`
      } else {
        return `${begin[1]}月${begin[2]}日-${end[1]}月${end[2]}日`
      }
    }
  }

  const toRedpacketSendPage = (activityCode: any, style: any, activityName: any, beginTime: any, endTime: any) => {
    history.push(`/cm/wecom/redpacketActivity/${activityCode}/${style}/${activityName}/${beginTime}/${endTime}/${storeCode}`);
  }

  return (<div className="activity-list-container">
    {activityList.map((activityItem: any) => 
      <div className='activity-item-wrap' key={activityItem.activityCode} onClick={()=>toRedpacketSendPage(activityItem.activityCode, activityItem.style, activityItem.activityName, activityItem.activityBeginTime,activityItem.activityEndTime)}>
        <div className='activity-item-left'>
          <div className='activity-time'>{getDate(activityItem.activityBeginTime,activityItem.activityEndTime)}</div>
          <div className='activity-name'>{activityItem.activityName}</div>
        </div>
        <div className='activity-item-right' />
      </div>
    )}
  </div>)
}