import config from "@/common/config/config";
// @ts-ignore
import http from "@omc/https";

const httpCtx = http({
  baseUrl: config.API_BFF_URL + "/bff",
  // baseUrl: 'https://api-sit.mcdchina.net' + '/bff',
  isCheckTid: false,
});

const httpCtx2 = http({
  baseUrl: "https://people-api-sit.mcdchina.net",
  // baseUrl: 'https://api-sit.mcdchina.net' + '/bff',
  isCheckTid: false,
});

export default {
  // 记录进群礼是否需要订阅接口
  record: (params: any) =>
    httpCtx.post(`/market/group/gift/record`, { params }),
  activityInvite: (activityCode: any) =>
    httpCtx.get(`/market/invite/activity/invite/${activityCode}`, {
      pathVars: [activityCode],
    }),
  activityAssistLaunch: (activityCode: any) =>
    httpCtx.get(`/market/assist/launch/${activityCode}`, {
      pathVars: [activityCode],
    }),
  activityConfig: (activityCode: any) =>
    httpCtx.get(`/market/activity/config/${activityCode}`, {
      pathVars: [activityCode],
    }),
  activityCarousel: (activityCode: any) =>
    httpCtx.get(`/market/invite/activity/carousel/${activityCode}`, {
      pathVars: [activityCode],
    }),
  activityAssistCarousel: (activityCode: any) =>
    httpCtx.get(`/market/assist/carousel/${activityCode}`, {
      pathVars: [activityCode],
    }),
  activityAssistPbz: (activityCode: any) =>
    httpCtx.get(`/market/assist/pbz/${activityCode}`, {
      pathVars: [activityCode],
    }),
  inviteRecord: (activityCode: any) =>
    httpCtx.get(`/market/invite/record/${activityCode}`, {
      pathVars: [activityCode],
    }),
  // 早餐卡邀请
  cardInvite: (taskId: any, headers: any) =>
    httpCtx.get(`/market/invite/task/${taskId}/basic`, {
      pathVars: [taskId],
      headers,
    }),
  cardInviteRecord: (taskId: any, headers: any) =>
    httpCtx.get(`/market/invite/task/${taskId}/data`, {
      pathVars: [taskId],
      headers,
    }),
  addCardInvite: (taskId: any, headers: any) =>
    httpCtx.post(`/market/invite/task/${taskId}/launch`, { headers }),
  acceptCardInviteBasic: (params: any, headers: any) =>
    httpCtx.get(`/market/invite/task/accept/basic`, { params, headers }),
  acceptCardInvite: (params: any, headers: any) =>
    httpCtx.get(`/market/task/invite/receive`, { params, headers }),
  getRewardDetail: (taskId: any, headers: any) =>
    httpCtx.get(`/market/invite/task/${taskId}/rewards`, {
      pathVars: [taskId],
      headers,
    }),
  // 绑定并登陆接口
  giftBind: (params: any) => httpCtx.post(`/passport/login/bind`, { params }),
  // 获取微信sign
  getWechatAuth: () =>
    httpCtx.get(`/smaug/woaap/js/signature`, {
      params: {
        url: window.location.href,
      },
    }),
  // 领取进群礼
  giftReceive: (params: any) =>
    httpCtx.post(`/market/group/gift/receive`, { params }),
  // 查询是否可领取进群礼
  giftCheck: () => httpCtx.get(`/market/group/gift/check`, {}),
  // 微信授权接口
  loginWx: (params: any) => httpCtx.post(`/passport/login/wx`, { params }),
  // 获取餐厅社区码接口
  giftQrCode: (params: any) =>
    httpCtx.get(`/market/group/join/index`, { params }),
  // 获取验证码接口
  send: (params: any) => httpCtx.post(`/passport/verifyCode/send`, { params }),

  // 登陆接口
  login: (params: any) => httpCtx.post(`/passport/login/mobile`, { params }),

  wecomLogin: (params: any) => httpCtx.post(`/market/wecom/login`, { params }),
  getWecomJsSignature: (params: any) =>
    httpCtx.get(`/market/wecom/js/signature`, { params }),
  getRgmRedpacketActivityList: (params: any, headers: any) =>
    httpCtx.get(`/market/rgm/activity/list`, { params, headers }),
  getRgmRedpacketList: (params: any, headers: any) =>
    httpCtx.get(`/market/rgm/redpacket/list`, { params, headers }),
  lockRgmRedpacket: (params: any, headers: any) =>
    httpCtx.post(`/market/rgm/redpacket/lock`, { params, headers }),
  bindRgmRedpacket: (params: any, headers: any) =>
    httpCtx.post(`/market/rgm/redpacket/bind`, { params, headers }),
  checkRgmRedpacketSend: (params: any, headers: any) =>
    httpCtx.get(`/market/rgm/redpacket/check`, { params, headers }),
  getCgmStoreList: (params: any, headers: any) => 
    httpCtx.get(`/market/clear/coupon/store/select`, { params, headers }),
  qrStoreJump: (params: any) =>
    httpCtx.get(`/portal/qr/store/jump`, { params }),
  generateQr: (params: any) =>
    httpCtx.get(`/bff/portal/qr/generate`, {
      params,
      baseUrl: config.API_BFF_URL,
    }),
  createPoster: (params: any, headers: any) =>
    httpCtx.get(`/market/app/poster/create/io`, {
      params,
      headers,
      responseType: "arraybuffer",
    }),
  queryStores: (params: any) => httpCtx.get(`/store/stores`, { params }),
  queryCity: (params: any) => httpCtx.get(`/store/cities`, { params }),
  queryStoreDetailByStoreCode: (storeCode: any) =>
    httpCtx.get(`/store/stores/address/${storeCode}`, {
      pathVars: [storeCode],
    }),
  updateLocation: (params: any) =>
    httpCtx.post(`/store/storeOps/updateLocation`, { params }),
  lotteryCheck: (params: any, headers: any) =>
    httpCtx.get(`/market/lottery/trade/check`, { params, headers }),
  lotteryTrade: (params: any, headers: any) =>
    httpCtx.post(`/market/lottery/trade`, { params, headers }),
  rewardRecord: (params: any, headers: any) =>
    httpCtx.get(`/market/achieve/list`, { params, headers }),
  queryDownloadImageSetting: (params: any, headers: any, ...rest: any[]) =>
    httpCtx.get("/portal/module/info", { params, headers, ...rest }),
  getCouponDetail: (params: any, headers: any) =>
    httpCtx.get(`/promotion/coupons/${params.id}`, {
      params: { couponId: params.couponId },
      pathVars: [params.id],
      headers,
    }),
  // https://api-docs.mcd.com.cn/project/659/interface/api/901682
  ccbLogin: (params: any) => httpCtx.post(`/passport/bcm/auth`, { params }),
  // https://api-docs.mcd.com.cn/project/659/interface/api/50650
  ccbLogout: (params: any) =>
    httpCtx.post(`/passport/user/cancel/logout`, { params }),
  // https://api-docs.mcd.com.cn/project/659/interface/api/90175
  ccbRegister: (code: any) =>
    httpCtx.post(`/passport/bcm/register`, { params: { code } }),

  // AKS获取活动状态 https://api-docs.mcd.com.cn/project/668/interface/api/113679
  getActivityStatus: (activityCode: any) =>
    httpCtx.get(`/market/lottery/common/basic`, { params: { activityCode } }),

  // AKS通用抽奖接口 https://api-docs.mcd.com.cn/project/668/interface/api/113735?
  drawForPrize: (params: any) =>
    httpCtx.post(`/market/lottery/common/pro/draw`, {
      params: {
        activityCode: params.activityCode,
        style: params.style,
      },
    }),

  // App专属接口
  appExclusive: (params: any) =>
    httpCtx.get(`/market/app/benefit/index`, { params }),

  // 神策数据短链
  jumpToApp: (params: any) =>
    httpCtx.post(`/market/api/tracking/create/shortUrl`, {
      params: {
        campaign: params.campaign,
        content: params.content,
        medium: params.medium,
        source: params.source,
        term: params.term,
        type: params.type,
      },
    }),

  getJumpWechatAuth: (params: any) =>
    httpCtx2.get(`/rct/portal/wx/jump/sign`, {
      params: {
        url: params.url,
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
      },
    }),
  
  // 获取跳转地址url
  getNormalLink: (params: any) => httpCtx.post(`/common/normal/link?noSign`, { params }),
};
