// https://m.mcd.cn/qr/s/20201001?utm_source=store&utm_medium=20201001

import React, {useEffect}from 'react';
import '@/assets/styles/qr-test.less';
import logoUrl from '@/assets/images/logo.svg';

export default () => {
  const isIos =  navigator.userAgent.includes('mcd-ios');
  const isAndroid = navigator.userAgent.includes('mcd-androi') || navigator.userAgent.includes('mcd-harmony');

  const appOpenUrl = 'mcdapp://page?iosPageName=MCDProductListViewController&androidPageName=ComponentProduct&androidPageAction=menu_list&harmonyPageName=ProductListPage&parameters=' + JSON.stringify({
    orderType: "1",
    repc: "12345",
    sc: "54321"
  })

  useEffect(() => {
    if(isIos || isAndroid) {
      window.location.href = appOpenUrl
    }
  })

  const goToDownload = () => {
    if(isIos) {
      window.location.href = 'https://apps.apple.com/cn/app/%E9%BA%A6%E5%BD%93%E5%8A%B3pro/id1054598922?l=en&from=singlemessage&isappinstalled=0'
    } else if(isAndroid) {
      window.location.href = 'market://details?id=com.mcdonalds.gma.cn'
    }
  }

  return (
    <div className="qr-test">
      <div className="header">
        <img src={logoUrl} />
      </div>
      <div className="actions">
        <div className="action" onClick={() => goToDownload()}>下载</div>
      </div>
    </div>
  );
}
