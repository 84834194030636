import React, { useState } from "react";
import apis from "@/common/net/apis";

export default (props: any) => {
  const {
    redpacketLeftCount,
    rgmLeftLockCount,
    anotherBatchViable,
    chatId,
    activityCode,
    sendCount,
    unSendCount,
    otherGoupCount,
    updateRedpacketInfo,
    wsid,
    groupFlag,
    sendTotal = 0,
    storeCode
  } = props;
  const [showToast, setShowToast] = useState(false);
  const [toastText, setToastText] = useState("");
  const [isDisableLock, setIsDisableLock] = useState(false);
  const lockRedpacket = () => {
    if (rgmLeftLockCount < 1 || unSendCount > 0 || anotherBatchViable !== 1) {
      // if (rgmLeftLockCount < 1 || unSendCount > 0) {
      return;
    }
    if (isDisableLock) return;
    setIsDisableLock(true);
    //重新请求红包列表判断，如果还剩没有发送的红包数量不请求接口
    apis
      .getRgmRedpacketList({ activityCode, chatId, groupFlag, storeCode }, { wsid })
      .then((res: any) => {
        setIsDisableLock(false);
        if (res) {
          if (res.data) {
            let leftCount: any = getLeftCount(res.data);
            updateRedpacketInfo(res.data || {}, 1, false, chatId, groupFlag);
            if (
              (groupFlag === 1 && leftCount > 0) ||
              (groupFlag === 0 && unSendCount > 0)
            ) {
              //弹窗提示
              showToastAction(
                groupFlag === 1 ? "领完才可以抢新的哟" : "发完才可以领的哟"
              );
              return;
            } else {
              const params = {
                activityCode: activityCode,
                chatId: chatId,
                groupFlag: groupFlag,
                storeCode: storeCode,
              };
              apis.lockRgmRedpacket(params, { wsid }).then((res: any) => {
                //更新页面数据 提示锁定成功，刷新页面
                showToastAction("恭喜抢成功，快点转发吧");
                updateRedpacketInfo(res.data || {}, 1, true, chatId, groupFlag);
              });
            }
          }
        }
      })
      .catch(() => {
        setIsDisableLock(false);
      });
  };
  const getLeftCount = (list: any) => {
    const { sendList = [], unSendList = [], otherGroupList = [] } = list;
    let sendLeftCount = sendList?.filter((i: any) => i.leftCount > 0).length;
    let unSendLeftCount = unSendList?.filter(
      (i: any) => i.leftCount > 0
    ).length;
    let otherLeftCount = otherGroupList?.filter(
      (i: any) => i.leftCount > 0
    ).length;
    return sendLeftCount + unSendLeftCount + otherLeftCount;
  };

  const showToastAction = (text: any) => {
    setShowToast(true);
    setToastText(text);
    setTimeout(() => {
      setShowToast(false);
    }, 2000);
  };

  return (
    <div className="footer-wrapper">
      {showToast && (
        <div className="toast-wrapper">
          <div className="toast">{toastText}</div>
        </div>
      )}
      {rgmLeftLockCount > 0 && redpacketLeftCount === 0 ? (
        <div className="no-redpacket">今日已抢完</div>
      ) : (
        <div className="footer-wrapper-block">
          <div>
            <div className="receive-num">
              已抢
              {sendTotal
                ? sendTotal + unSendCount
                : sendCount + unSendCount + otherGoupCount}
              个，<span className="unsend-num">{unSendCount}</span>个未使用
            </div>
            <div className="text">
              {rgmLeftLockCount > 0
                ? "数量有限，发完再抢一批"
                : "本轮抢红包已达上限"}
            </div>
          </div>
          <div
            className={
              rgmLeftLockCount > 0 &&
              unSendCount === 0 &&
              !isDisableLock &&
              anotherBatchViable === 1
                ? "lock-more"
                : "no-lock"
            }
            onClick={lockRedpacket}
          >
            {isDisableLock ? "发放中..." : "再抢一批"}
          </div>
        </div>
      )}
    </div>
  );
};
