var path = module.exports;

var isAbsolute =
/**
 * Tests if the specified path is absolute.
 * @param {string} path Path to test
 * @returns {boolean} `true` if path is absolute
 */
    path.isAbsolute = function isAbsolute(path) {
        return /^(?:\/|\w+:)/.test(path);
    };

var normalize =
/**
 * Normalizes the specified path.
 * @param {string} path Path to normalize
 * @returns {string} Normalized path
 */
    path.normalize = function normalize(path) {
        path = path.replace(/\\/g, "/")
            .replace(/\/{2,}/g, "/");
        var parts    = path.split("/"),
            absolute = isAbsolute(path),
            prefix   = "";
        if (absolute)
            prefix = parts.shift() + "/";
        for (var i = 0; i < parts.length;) {
            if (parts[i] === "..") {
                if (i > 0 && parts[i - 1] !== "..")
                    parts.splice(--i, 2);
                else if (absolute)
                    parts.splice(i, 1);
                else
                    ++i;
            } else if (parts[i] === ".")
                parts.splice(i, 1);
            else
                ++i;
        }
        return prefix + parts.join("/");
    };

/**
 * Resolves the specified include path against the specified origin path.
 * @param {string} originPath Path to the origin file
 * @param {string} includePath Include path relative to origin path
 * @param {boolean} [alreadyNormalized=false] `true` if both paths are already known to be normalized
 * @returns {string} Path to the include file
 */
path.resolve = function resolve(originPath, includePath, alreadyNormalized) {
    if (!alreadyNormalized)
        includePath = normalize(includePath);
    if (isAbsolute(includePath))
        return includePath;
    if (!alreadyNormalized)
        originPath = normalize(originPath);
    return (originPath = originPath.replace(/(?:\/|^)[^/]+$/, "")).length ? normalize(originPath + "/" + includePath) : includePath;
};
