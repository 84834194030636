// https://m.mcd.cn/qr/s/20201001?utm_source=store&utm_medium=20201001

import React, { useEffect, useState } from 'react';
import '@/assets/styles/qr-store.less';
import imgUrl from '@/assets/images/scan.png';
import loading from '@/assets/images/loading.gif';
import apis from '@/common/net/apis';
import { throttle } from "lodash";
import browserizr, { isAndroid, isIOS } from '@wezom/browserizr';
/** @ts-ignore */
import { getWechat } from '@omc/browserizr';


// import Browserizr from 'browserizr';
// import setupWebViewJavascriptBridge from '@/common/bridge';

export default () => {
  const params = new URLSearchParams(window.location?.search);
  const [storeCode, tableId] = [params.get('utm_term') ?? '', params.get('tableId') ?? ''];
  const userAgent: any = navigator.userAgent.toLowerCase();
  const isWeixin = userAgent.includes('micromessenger');
  const isAlipay = userAgent.includes('alipayclient');
  const isApp: any = userAgent.includes('mcd-ios') || userAgent.includes('mcd-android') || userAgent.includes('mcd-harmony');
  const isAndroidApp:any = userAgent.includes('mcd-android') || userAgent.includes('mcd-harmony');
  const [isShowDownload, setIsShowDownload] = useState(false)
  // const isIosBrowser = Browserizr.detect().isIOS();
  // const isAndroidBrowser = Browserizr.detect().isAndroid();
  // const iosDownloadUrl = 'https://apps.apple.com/cn/app/%E9%BA%A6%E5%BD%93%E5%8A%B3pro/id1054598922?l=en&from=singlemessage&isappinstalled=0'
  // const androidDownloadUrl = 'market://details?id=com.mcdonalds.gma.cn';
  const appOpenUrl = 'mcdapp://page?iosPageName=MCDProductListViewController&androidPageName=ComponentProduct&androidPageAction=menu_list&harmonyPageName=ProductListPage&parameters=' + JSON.stringify({
    orderType: "1",
    sc: storeCode,
    tableId: tableId,
    closeCurrent: true
  })

  useEffect(() => {
    const params = {
      storeId: storeCode,
      tableId,
      channel: isApp ? 'cma' : (isAlipay ? 'alipay' : (isWeixin ? 'wechat' : 'h5'))
    };
    apis.qrStoreJump(params).then((res: any) => {
      if (res && res.success && res.data) {
        let { type, url, argu } = res.data
        if (type === 2) {
          //h5
          window.location.replace(`${url}${/\?/.test(url) ? '&' : '?'}${argu}`);

          const sensors: any = (window as any).sensors;
          sensors.track('ChannelJump', {
            app_name: 'H5定向跳转页',
            us_code: storeCode,
            platform_type: "浏览器",
            promotion_name: '一店一码',
            url: url
          });
        } else if (type === 3) {
          //APP
          const code = argu.split('=')[1]
          if (/parameters/.test(url)) {
            const urlArr = url.split('&');
            const newUrl = urlArr.map((i: any) => {
              let item = i.split('=')
              if (item[0] === 'parameters') {
                let arguObj = JSON.parse(item[1]);
                let newArguObj = {
                  sc: code,
                  closeCurrent: true,
                  ...arguObj
                }
                i = 'parameters=' + JSON.stringify(newArguObj)
              }
              return i;
            })
            url = newUrl.join('&');
          } else {
            url += '&parameters=' + JSON.stringify({
              sc: code,
              closeCurrent: true,
            })
          }
          let platformType = 'Android';
          if (url.includes('iosPageName')) {
            platformType = 'IOS';
          }
          const sensors: any = (window as any).sensors;
          sensors.track('ChannelJump', {
            app_name: 'H5定向跳转页',
            us_code: storeCode,
            platform_type: platformType,
            promotion_name: '一店一码',
            url: url
          });
          if (isAndroidApp) {
            connectWebViewJavascriptBridge((bridge: any) => {
              bridge.callHandler("closeWebView", {}, function (data: any) {
              });
            })
          }
          window.location.href = url;
          setTimeout(() => {
            setIsShowDownload(true)
          }, 2000)
        } else {
          goToAppOrder()
        }
      } else {
        goToAppOrder()
      }
    })
  }, [])

  const goToAppOrder = () => {
    if (isApp) {
      if (isAndroidApp) {
        connectWebViewJavascriptBridge((bridge: any) => {
          bridge.callHandler("closeWebView", {}, function (data: any) {
          });
        })
      }

      window.location.href = appOpenUrl
    }
  }

  const connectWebViewJavascriptBridge = (callback: any) => {
    if (window.WebViewJavascriptBridge) {
      callback(window.WebViewJavascriptBridge);
    } else {
      document.addEventListener(
        'WebViewJavascriptBridgeReady'
        , function () {
          callback(window.WebViewJavascriptBridge)
        },
        false
      );
    }
  }

  function downloadAtOnce() {
    if (getWechat()) {
      alert('点击右上角在浏览器中打开');
    } else {
      const document: any = window.document;
      let hidden = document.hidden || document.mozHidden || document.msHidden || document.webkitHidden;
      if (!hidden) {
        let href;
        if (browserizr.detect(isIOS)) {
          href = 'https://apps.apple.com/cn/app/id1054598922'
        } else if (browserizr.detect(isAndroid)) {
          href = 'market://details?id=com.mcdonalds.gma.cn'
        } else {
          href = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.mcdonalds.gma.cn'
        }
        window.location.href = href;
      }
    }
  }
  const onDownloadClick = throttle(downloadAtOnce.bind(null), 500, { 'leading': true, 'trailing': false });

  const onSureClick = () => {
    if (isApp) {
      setIsShowDownload(false)
      connectWebViewJavascriptBridge((bridge: any) => {
        bridge.callHandler("closeWebView", {}, function (data: any) {
        });
      })
    } else {
      onDownloadClick()
    }
  }

  const onClose = () => {
    setIsShowDownload(false)
    if (isApp) {
      connectWebViewJavascriptBridge((bridge: any) => {
        bridge.callHandler("closeWebView", {}, function (data: any) {
        });
      })
    }
  }

  return (
    <div className="qr-store">
      {
        isApp && <div>
          <div className="header">
            <img className="loading" src={loading} />
          </div>
          <div className="actions">
            <div className="text">正在加载</div>
          </div>
        </div>
      }
      {
        !isApp && <div>
          <div className="header">
            <img className="logo" src={imgUrl} />
          </div>
          <div className="actions">
            <div className="text">请使用微信或支付宝扫码</div>
          </div>
        </div>
      }
      {
        isShowDownload && <div className="download-pop">
          <div className="download-block">
            <img className="download-close" src="https://img.mcd.cn/gallery/639020210203103647460.png" onClick={() => onClose()} />
            <div className="download-title">无法打开？</div>
            <div className="download-text">{isApp ? '跳转失败，请确认跳转链接' : '唤起 麦当劳APP 失败，您可以点击下载安装'}</div>
            <div className="download-btn" onClick={() => onSureClick()}>{isApp ? '确定' : '立即下载'}</div>
          </div>
        </div>
      }
      {/* {
        (isWeixin || isWeixin) && */}
      {/* <div> */}
      {/* <div className="header">
        <img className="logo" src={imgUrl} />
      </div>
      <div className="actions">
        <div className="text">请使用微信或支付宝扫码</div>
      </div> */}
      {/* </div>
      } */}
      {/* {
        (!isWeixin && !isWeixin) &&
        <div>
          <div className="header">
            <img className="logo" src={logoUrl} />
          </div>
          <div className="actions">
            <div className="action" onClick={() => goToDownload()}>下载</div>
          </div>
        </div>
      } */}
    </div>
  );
}