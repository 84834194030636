import React, { useEffect, useRef, useState } from 'react';
import '@/assets/styles/store-tool.less';
// import img1400517 from '@/assets/images/storeImg/1400517.png'
// const mapImg:any = {3500013:img3500013}

export default () => {
  const deviceRatio = 1
  const img: any = document.querySelector('#J_image');
  // const codes: any = [3500013]
  // useEffect(() => {
  //   let j = 0;
  //   for (let i = 0; i < codes.length; i++) {
  //     setTimeout(() => {
  //       createQr(mapImg[codes[j]], codes[j]);
  //       j++
  //     }, 500 * i)
  //   }
  // })
  const drawAllImages = (ctx: any, qrImage: any, usCode: any) => {
    const p1: any = drawImage(ctx, qrImage, 10, 10, 330, 330, usCode);
    return Promise.all([p1])
  }
  function drawImage(ctx: any, qrImage: any, w0: any, h0: any, w1: any, h1: any, usCode: any) {
    return new Promise(function (resolve, reject) {
      const img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');
      img.onload = function () {
        ctx.drawImage(img, 25 * deviceRatio, 25 * deviceRatio, 1000 * deviceRatio, 1000 * deviceRatio);
        img.style.display = 'none';
        resolve(img);
      }
      img.onerror = function () {
        reject();
      }
      img.src = qrImage;
    })
  }
  //方法一
  // const [QrSvg, setQrSvg] = useState(<div></div>)
  // useEffect(() => {
  //   let str: any = document.getElementById('qrSvgElement')?.innerHTML;
  //   const qrsvgCode = `data:image/svg+xml;base64,` + Base64.encode(str);
  //   //绘制canvas开始
  //   const canvas: any = document.getElementById('canvas');
  //   canvas.width = canvas.clientWidth * deviceRatio;
  //   canvas.height = canvas.clientHeight * deviceRatio;
  //   const ctx = canvas.getContext('2d');
  //   (async () => {
  //     ctx.fillStyle = '#fff';
  //     ctx.fillRect(0, 0, 350 * deviceRatio, 350 * deviceRatio);
  //     drawAllImages(ctx, qrsvgCode);
  //     ctx.fillStyle = '#000';
  //     ctx.textAlign = 'right';
  //     ctx.font = "60px Microsoft YaHei";
  //     ctx.fillText(usCode, 295 * deviceRatio, 315 * deviceRatio);
  //     const toImage = canvas.toDataURL('image/png', 1);
  //     const img = document.createElement('img');
  //     img.src = toImage
  //   })()
  // }, [QrSvg])
  // }
  //方法二
  const createQr = (img: any, usCode:any) => {
    (async () => {
      let qrImage = img;
      let canvas: any = document.getElementById('canvas');
      canvas.style.display = 'block'
      canvas.width = canvas.clientWidth * deviceRatio;
      canvas.height = canvas.clientHeight * deviceRatio;
      const ctx = canvas.getContext('2d');
      ctx.fillStyle = '#fff';
      ctx.fillRect(0, 0, 1050 * deviceRatio, 1050 * deviceRatio);
      await drawAllImages(ctx, qrImage, usCode);
      ctx.fillStyle = '#fff';
      ctx.fillRect(650 * deviceRatio, 980 * deviceRatio, 340 * deviceRatio, 60 * deviceRatio);
      ctx.fillStyle = '#000';
      ctx.textAlign = 'right';
      ctx.font = "500 60px Speedee";

      ctx.fillText(usCode, 975 * deviceRatio, 1035 * deviceRatio);
      canvas.style.display = 'none'
      const toImageUrl = canvas.toDataURL('image/png', 1);

      const a = document.createElement('a');
      const event = new MouseEvent('click');
      a.download = usCode;
      a.href = toImageUrl;
      // a.dispatchEvent(event);

      // img.style.display = 'block';
      // img.crossOrigin = "anonymous";
      // img.src = toImageUrl;
    })()
  }
  return (
    <div className="store-tool-wrapper">
      <div className="title">门店二维码生成工具</div>
      <canvas id="canvas" style={{ display: 'none' }} width={1050 * deviceRatio} height={1050 * deviceRatio}></canvas>
      <img id="J_image" className="qr-image" crossOrigin="anonymous" />
    </div>
  )
}