import React, { useEffect, useState, useRef } from 'react';
import Cookies from 'js-cookie';
import tool from '@/common/utils/tool';
import apis from '@/common/net/apis';
import config from '@/common/config/config';
import RedpacketActivityList from '@/components/redpacket/RedpacketActivityList';
import RedpacketStoreList from '@/components/redpacket/RedpacketStoreList';
import '@/assets/styles/redpacket/activityList.less';
import ampHandler from "@/utils/apm";
const APP_ID = 'wx42cf5e73876fe3b9';
const AGENT_ID = config.AGENT_ID;
const LOADING_GIF = 'https://img.mcd.cn/gallery/313420210205111604433.gif';
const EMPTY_IMG = 'https://img.mcd.cn/gallery/538020210128050155064.png';
const storeIcon = 'https://img.mcd.cn/gallery/787adaba009dad64.png'; // 餐厅地址icon
const storeArrow = 'https://img.mcd.cn/gallery/a5b025824d6e1ec9.png'; // 餐厅下拉icon

export default () => {
  const wx: any = (window as any).wx;
  const code: any = tool.getSearchByKey('code');
  const fromRedirect: any = tool.getSearchByKey('fromRedirect') || 0
  const [wsid, setWsid] = useState(Cookies.get('wsid'));
  const [chatId, setChatId] = useState('');
  const [isLogin, setIsLogin] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [groupFlag, setGroupFlag] = useState(0);
  const [showStoreList, setShowStoreList] = useState(false);
  const storeList: any = useRef([]);
  const [currentStore, setCurrentStore]: any = useState(null);
  const selectStoreRef: any = useRef(null);
  const optionFlag = useRef(0);
  const [redpacketActivityList, setRedpacketActivityList]: any = useState([]);

  const newChatId = useRef('');
  newChatId.current = chatId;

  useEffect(() => {
    document.title = '社群红包';
  }, [])

  useEffect(() => {
    // 没有sid 或未登录时跳网页授权获取code
    if (!wsid || !isLogin) {
      if (!code) {
        //没有code去授权
        userOauth();
      } else {
        // 有code换取wsid
        getWsid(code)
      }
    }
    if (wsid && isLogin) {
      //config认证及agengConfig认证
      agentConfigApi();
    }
  }, [isLogin, wsid])

  const userOauth = () => {
    let redirect_uri = encodeURIComponent(window.location.href);
    let href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APP_ID}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`
    window.location.href = href;
  }
  const getWsid = (code: any) => {
    apis.wecomLogin({ code: code, agentId: AGENT_ID }).then((res: any) => {
      if (res && res.data && res.data.wsid) {
        setIsLogin(true);
        setWsid(res.data.wsid);
        Cookies.set('wsid', res.data.wsid, { expires: 15 })
      }
    })
  }
  const agentConfigApi = () => {
    apis.getWecomJsSignature({ url: window.location.href }).then((res: any) => {
      const { sign, timestamp, nonceStr } = res.data || {};
      setTimeout(() => {
        if (!newChatId.current && !fromRedirect && groupFlag === 1) {
          const currentLocation = window.location.href;
          window.location.href += `${currentLocation.includes('?') ? '&' : '?'}fromRedirect=1`
        }
      }, 2000)
      wx && wx.agentConfig({
        corpid: APP_ID,
        agentid: AGENT_ID,
        timestamp: timestamp,
        nonceStr: nonceStr,
        signature: sign,
        jsApiList: ['getCurExternalChat', 'sendChatMessage', 'getContext'],
        success: function (res: any) {
          ampHandler.uploadEventInfo('agentConfigSuccess', Date.now(), 'agentConfig success' + JSON.stringify(res));
          wx.invoke('getContext', {}, (res: any) => {
            ampHandler.uploadEventInfo('getContext', Date.now(), 'getContext result' + JSON.stringify(res));
            if (res.err_msg === 'getContext:ok') {
              let entry = res.entry
              if (entry === 'group_chat_tools') {
                setGroupFlag(1)
                wx.invoke('getCurExternalChat', {}, (res: any) => {
                  ampHandler.uploadEventInfo('getCurExternalChat', Date.now(), 'getCurExternalChat result' + JSON.stringify(res));
                  if (res.err_msg === 'getCurExternalChat:ok') {
                    setChatId(res.chatId);
                    getRedpacketActivityList(1, '');
                  } else {
                    setIsLoading(false);
                  }
                })
              } else if (entry === 'single_chat_tools') {
                setGroupFlag(0)
                getRedpacketActivityList(0, '');
              } else {
                setGroupFlag(3)
                setIsLoading(false)
              }
            }
          })
        },
        fail: (e: any) => {
          setIsLoading(false);
        }
      })
    })
  }

  const getRedpacketActivityList= (groupFlag: any, storeCode: any) => {
      apis.getRgmRedpacketActivityList({groupFlag, storeCode}, { wsid })
      .then((res: any) => {
        if (res) {
          setIsLoading(false);
          if (res.code === 401) {
            setIsLogin(false);
          }
          if (res.data) {
            optionFlag.current = res.data?.optionFlag || 0;
            if (res.data?.storeCode && res.data?.storeName) {
              if (currentStore?.storeCode) {
              } else {
                setCurrentStore({storeCode: res.data?.storeCode, storeName: res.data?.storeName});
              }
            }
            setRedpacketActivityList(res.data.rgmActivityList || [])
          } else {
            setCurrentStore(null);
            optionFlag.current = 0;
            setRedpacketActivityList([]);
          }
        }
      })
  }

  const fetchCgmStoreData = () => {
    apis.getCgmStoreList({wsid}, { wsid }).then((res: any) => {
      if (res.data) {
        storeList.current = res.data?.storeList || [];
        setShowStoreList(true);
      } else {
        storeList.current = [];
      }
    })
  }

  return (
    <div className='redpacket-activityList-bg'>
      <div className="redpacket-activityList-wrapper">
        {
          isLoading 
          ? <div className="loading"><img className="loading-img" src={LOADING_GIF} /></div> 
          : <>
              {!showStoreList && currentStore && <div className="store-view" onClick={() => {
                if (optionFlag.current==1) {
                  if (storeList.current?.length>0) {
                    setShowStoreList(true);
                  } else {
                    fetchCgmStoreData();
                  }
                }
              }}>
                <img className="store-icon" src={storeIcon} />
                <div className="store-name">{currentStore.storeName}</div>
                {optionFlag.current==1 && <img className="store-arrow" src={storeArrow} />}
              </div>}
              {!showStoreList && !(redpacketActivityList?.length>0) && <div>
                <img src={EMPTY_IMG} className="empty-img" />
                <p className="empty-text">{groupFlag==3? '请从聊天工具栏进入' :'当前没有活动'}</p>
              </div>}
              {!showStoreList && redpacketActivityList?.length>0 && <RedpacketActivityList activityList={redpacketActivityList} storeCode={currentStore?.storeCode || ''}/>}
              {showStoreList && <RedpacketStoreList storeList={storeList.current} selectedCode={currentStore?.storeCode} currentClickStore={(storeItem: any) => {
                selectStoreRef.current = storeItem;
              }}/>}
              {showStoreList && <div className='confirm-button' onClick={() => {
                setCurrentStore(selectStoreRef.current);
                setShowStoreList(false);
                getRedpacketActivityList(groupFlag, selectStoreRef.current?.storeCode);
              }}>
                <div className='confirm-button-view'>确定</div>
              </div>}
            </>
        }
      </div>
    </div>)
}