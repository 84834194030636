const getCookieByKey = (key) => {
  let keyValue;
  document.cookie.split(';').forEach((item) => {
    if (item.includes(key)) {
      let keyMap = item.split('=');
      if(keyMap[0].replace(' ', '') === key) {
        keyValue =  keyMap[1]
      }
    }
  })
  return keyValue;
}

const getSearchByKey = (key) => {
  let keyValue;
  let search = window.location.search;
  if(!search || !key) return;

  search.slice(1).split('&').filter(i => i.includes(key)).map(v => {
    let keyMap = v.split('=');
    if(keyMap[0] === key) {
      keyValue = keyMap[1]
    }
  })

  return keyValue;
}

function Utf8ArrayToStr(array) {
  var out, i, len, c;
  var char2, char3;

  out = "";
  len = array.length;
  i = 0;
  while (i < len) {
    c = array[i++];
    switch (c >> 4) {
      case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
        // 0xxxxxxx
        out += String.fromCharCode(c);
        break;
      case 12: case 13:
        // 110x xxxx   10xx xxxx
        char2 = array[i++];
        out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
        break;
      case 14:
        // 1110 xxxx  10xx xxxx  10xx xxxx
        char2 = array[i++];
        char3 = array[i++];
        out += String.fromCharCode(((c & 0x0F) << 12) |
          ((char2 & 0x3F) << 6) |
          ((char3 & 0x3F) << 0));
        break;
    }
  }
  return out;
}

export default {
  getCookieByKey,
  getSearchByKey,
  Utf8ArrayToStr,
}