import React, { useEffect, useRef } from 'react'
// import '~video-react/dist/video-react.css';
// @ts-ignore
import { Player, ControlBar, PlayToggle, BigPlayButton, ForwardControl } from 'video-react';
import "../../node_modules/video-react/dist/video-react.css"
import '@/assets/styles/videoDemo.less';
// @ts-ignore
import ReactPlayer from 'react-player'

export default () => {
  let videoUrl = 'https://img.mcd.cn/cms/images/4fb6e0a4f31b9622.MP4'
  let poster = 'https://img.mcd.cn/cms/images/6cddf2e789505cf5.jpg'

  return <div className='demo-wrapper'>
   
    <div>
      <div className='video-type'>Video-React Demo</div>
      {/* {vrProperty.map((i) => <p className='video-item'>{i}</p>)} */}
      <Player playsInline
        poster={poster}
        src={videoUrl}
        muted={true}
        width={'100%'}
        height={'100%'}
      // autoPlay
      >
        <ControlBar autoHide={true}
          className="vp-bar"
        //  disableDefaultControls={true}
        >
          {/* <PlayToggle />
          <ForwardControl seconds={5} order={2.1} />
          <ForwardControl seconds={10} order={2.2} /> */}
        </ControlBar>
        <BigPlayButton position='center' />
      </Player>
    </div>
    <div>
      <div className='video-type'>
        React-Player Demo
      </div>
      <div style={{ height: 210 }}>
        <ReactPlayer url={videoUrl} width='100%' height="100%" controls={true} playsinline
          light={poster}
          loop={false}
          // playing={true} 
          muted={true} />
      </div>
    </div>
    <div style={{ width: '100%' }}>
      <div className='video-type'>
        原生video Demo
      </div>
      <video controls style={{ width: '100%' }}
        // autoPlay
        id='native-video'
        poster={poster}
        playsInline
        muted={true}
        src={videoUrl}></video>
    </div>
    <div className='video-type'>对比结果</div>
    <img src="https://img.mcd.cn/gallery/318321ae6d4508e8.png " style={{ width: '100%' }} />

  </div>
}