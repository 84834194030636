import {useCallback, useEffect, useState} from "react";
import Cookies from "js-cookie";
import {Progress, Modal, Button} from "antd";
import tool from "@/common/utils/tool";
import apis from "@/common/net/apis";
import moment from "moment";
import cx from "classnames";
import learnMore from "../assets/images/learn-more.png";
import doneImg from "../assets/images/done.png";
import bottomImg from "../assets/images/bottom-img.png";
import checkReward from "@/assets/images/button.png";
import expireImg from '@/assets/images/activity-expire.png';
import "@/assets/styles/inviteDownload.less";
import {client} from "@/common/common";
import {Base64} from 'js-base64';
import {getCt} from "@/utils/common";

declare const window: Window & { wx: any }

interface Person {
  avatar: string; //头像
  nickName: string; //昵称
  icon: string; //icon,
  status: number; // 1: 未完成任务 2: 完成了任务 3: 完成任务后退单
  statusText: string; // 下单状态文案 如 购卡成功
  statusRightDesc: string; //下单状态描述 “奖励已发放到您的账户”
  statusLeftDesc: string; //下单状态描述 “已购卡”
  tagName: string; // 标签标题 如新客
}

interface STEPS {
  picture: string;
  target: string;
}

interface SCHEDULE {
  subTitle: string[];
  steps: STEPS[];
  allSuccess: boolean;
  lastIndex: number;
}

export default () => {
  const [delDate, setDelDate] = useState(0);
  const userAgent: any = navigator.userAgent.toLowerCase();
  const sid = tool.getSearchByKey("sid") || Cookies.get("sid") || "";
  Cookies.set("sid", sid);
  const isApp =
    userAgent.includes("mcd-ios") || userAgent.includes("mcd-android") || userAgent.includes('mcd-harmony');
  const taskId: any = tool.getSearchByKey("taskId");
  const [checkMore, setCheckMore] = useState(false);
  const [visible, setVisible] = useState(false);
  const [progressWidth, setprogressWidth] = useState(0)
  const [activityInfo, setActivityInfo] = useState({
    activityName: "", // 页面名称
    activityCoverImg: "", //接受邀请页面背景图
    bgColor: "", //接受邀请页面背景色
    ruleDesc: "", //活动规则
    shareImg: "", //分享图
    repostMsg: "", //分享文案
    currentTime: "", //服务器时间
    endTime: "", //活动结束时间
    status: undefined, //活动状态 活动呈现状态 0 待发布；1 待上线；2 上线中；3 已结束；4 已下线
    inviteBtn: "", // 立即邀请按钮图片
    stepImg: "", // 邀请步骤图片
    achievementImg: "", // 我的成就图片
    inviteRecord: "", // 邀请记录图片
    myRewardImage: "", // 我的奖励图片
    moreShareImage: "", // 更多分享方式
    relActivityContent: undefined, // 关联的活动内容 0：其他 1：邀请下载APP
    inviteLink: "", // 邀请链接
    posterImg: "", //海报图
    ladderRewardImage: "", //阶梯奖励
    jumpShortLink: "", // 跳转短链
    noLoginButtonImage: "", // 未登录btn图片
    repostSubMsg: '', // 副标题
    peopleCheck: true, // 是否允许邀请
    tocTip: '', // 不允许参与文案
  });
  const [schedule, setSchedule] = useState({} as SCHEDULE);
  const [isLogin, setIsLogin] = useState(true);
  const [isExpire, setExpire] = useState(false);
  const [base64Url, setBaseUrl] = useState('');
  const [checkVisible, setCheckVisible] = useState(false);
  const [record, setRecord] = useState(
    [] as { desc: string; number: number; unit: string }[]
  );
  const [persons, setPersons] = useState([] as Person[]);

  const image2Base64 = (url: string) => {  //图片转Base64
    return new Promise(r => {
      const img = new Image();
      img.setAttribute("crossOrigin", 'Anonymous') //允许跨域
      img.src = url;
      img.onload = function () {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx: any = canvas.getContext("2d");
        const ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase();
        ctx.drawImage(img, 0, 0, img.width, img.height);
        const baseUrl = canvas.toDataURL("image/" + ext);
        r(baseUrl)
        setBaseUrl(baseUrl);
      }
    })
  }
  const ct = getCt();

  useEffect(() => {
    getActivityConfig();
    getActivityDetail();
  }, []);

  useEffect(() => { //倒计时
    let newTimer: any = null;
    newTimer = setInterval(() => {
      if (delDate > 0) {
        if (delDate > 1000) {
          setDelDate(c => c - 1000);
        } else {
          setDelDate(0);
        }
      }
    }, 1000);
    return () => clearInterval(newTimer);
  }, [delDate]);

  const getActivityConfig = () => {
    apis.cardInvite(taskId, {sid, ct}).then(
      async (res: any) => {
        if (res) {
          setActivityInfo(res.data || {});
          const {endTime, currentTime, activityName, status, posterImg} = res.data;
          setExpire(status !== 2); // 2 代表上线中
          image2Base64(posterImg);
          document.title = activityName || '邀请好友下载APP活动';
          const formatEndTime = endTime?.replace(/\-/g, "/");
          const formatCurrentTime = currentTime?.replace(/\-/g, "/");
          const gap = moment(new Date(formatEndTime).getTime()).diff(
            new Date(formatCurrentTime).getTime()
          );
          setDelDate(gap > 0 ? gap : 0);
        }
      },
      () => {
      }
    );
  };
  const appShareToWechat = () => {
    client.connectWebViewJavascriptBridge((bridge: any) => {
      let params = JSON.stringify({
        title: activityInfo.repostMsg || "好友分享给你一个活动",
        content: activityInfo.repostSubMsg || "",
        url: activityInfo.inviteLink,
        imageUrl: activityInfo.shareImg,
        channels: [
          {
            channel: 0,
            type: 2
          },
          {
            channel: 1,
            type: 2
          },
          {
            channel: 3,
            type: 2
          },
          {
            channel: 4,
            type: 2
          },
        ],
        userName: 'gh_f1f7cebb53a3',
        path: activityInfo.inviteLink,
        //showPosterImg: 'true',
        posterImg: base64Url,
      });
      bridge.callHandler("startShare", params, (data: any) => {
        // Do Nothing
      });
    });
  };

  const getFinalTime = useCallback(() => {
    const del = moment.duration(delDate);
    const _days = del.get("days");
    const _hours = del.get("hours");
    const _minutes = del.get("minutes");
    const _seconds = del.get("seconds");
    return {
      days: String(_days).length > 1 ? _days : "0" + _days,
      hours: String(_hours).length > 1 ? _hours : "0" + _hours,
      minutes: String(_minutes).length > 1 ? _minutes : "0" + _minutes,
      seconds: String(_seconds).length > 1 ? _seconds : "0" + _seconds
    };
  }, [delDate]);

  const invitePerson = () => {
    if (isApp) {
      if (!isLogin) {
        window.location.href = "https://mcd.cn/login";
      } else {
        if (!activityInfo.peopleCheck) {
          setCheckVisible(true);
          return
        } else {
          client.connectWebViewJavascriptBridge((bridge: any) => {
            let params = JSON.stringify({
              title: activityInfo.repostMsg || "好友分享给你一个活动",
              content: activityInfo.repostSubMsg || "",
              url: activityInfo.inviteLink,
              imageUrl: activityInfo.shareImg,
              channels: [
                {
                  channel: 0,
                  type: 2
                }
              ],
              userName: 'gh_f1f7cebb53a3',
              path: activityInfo.inviteLink,
              posterImg: Base64.encode(activityInfo.posterImg),
            });
            bridge.callHandler("startShare", params, (data: any) => {
              // Do Nothing
            });
          });
        }
      }
    } else {
      window.location.href = activityInfo.jumpShortLink;
    }
  };

  const moreInvite = () => {
    if (isLogin) {
      if (!activityInfo.peopleCheck) {
        setCheckVisible(true);
        return
      } else {
        appShareToWechat();
      }
    } else {
      window.location.href = "https://mcd.cn/login";
    }
  }
  const getActivityDetail = () => {
    apis.cardInviteRecord(taskId, {sid, ct}).then(
      (res: any) => {
        if (res) {
          const {achievementRecords, persons, schedule} = res.data || {};
          if (!achievementRecords && !persons) {
            setIsLogin(false)
          }
          setRecord(achievementRecords);
          setPersons(persons);
          setSchedule(schedule);
          setprogressWidth(document.getElementById("target-group")?.scrollWidth || 0)
          schedule && document
            .getElementById(`step${schedule?.lastIndex}`)
            ?.scrollIntoView();

        }
      },
      () => {
      }
    );
  };

  return (
    <div
      className="invite-download"
      style={!isExpire ? {backgroundColor: `#${activityInfo.bgColor}`} : {}}
    >
      {!isExpire ? (<div>
        <div className="invite-cover">
          <div className="activity-rule" onClick={() => setVisible(true)}>
            活动规则
          </div>
          <div className="invite-cover-btn-box">
            <div className="invite-cover-time">

              距开奖仅剩
              <div className="invite-cover-time-detail">
                {delDate ? getFinalTime().days : "00"}
              </div>
              天
              <div className="invite-cover-time-detail">{getFinalTime().hours}</div>
              时
              <div className="invite-cover-time-detail">
                {getFinalTime().minutes}
              </div>
              分
              <div className="invite-cover-time-detail">
                {getFinalTime().seconds}
              </div>
              秒
            </div>
            <div onClick={invitePerson} className="invite-cover-btn">
              <img
                src={
                  (isLogin || !isApp)
                    ? activityInfo.inviteBtn
                    : activityInfo.noLoginButtonImage
                }
              />
            </div>

            {isApp && <div className="more-invite-container" onClick={moreInvite}>
              <img
                className="more-invite-img"
                src={activityInfo.moreShareImage}
              />
            </div>}
          </div>

          <img className="invite-cover-bg" src={activityInfo.activityCoverImg}/>
        </div>
        <div
          className="invite-detail"
          style={{backgroundColor: activityInfo.bgColor}}
        >
          {isLogin ? (<div className="invite-detail-item-third">
            <img
              className="more-invite-img"
              src={activityInfo.ladderRewardImage}
            />
            <div className="more-invite">
              {schedule?.subTitle && (
                <div className="more-invite-title" style={!isLogin ? {marginTop: 0} : {}}>
                  {schedule?.subTitle[0]}
                  <span className="spec-color">{schedule?.subTitle[1]}</span>
                  {schedule?.subTitle[2]}
                </div>
              )}
              <div className="progress-container" id="target-group">
                <div className="target-group">
                  {schedule?.steps?.map((item, index) => (
                    <div
                      onClick={() =>
                        document.getElementById(`step${index}`)?.scrollIntoView()
                      }
                      key={index}
                      className="target-group-item"
                      id={`step${index}`}
                    >
                      <img className="item-img" src={item.picture}/>
                      {schedule?.lastIndex >= index ? (
                        <img className="item-done-img" src={doneImg}/>
                      ) : (
                        <div className="item-done-div"/>
                      )}
                      <div className="item-target" key={index}>
                        {item.target}
                      </div>
                    </div>
                  ))}
                </div>
                {schedule?.steps && <Progress //活动进度条调整
                  style={{
                    width: `${progressWidth}px`,
                    position: "absolute",
                    top: "70px",
                    left: 0
                  }}
                  strokeColor="#c92a1f"
                  trailColor="#fbe9c7"
                  percent={
                    schedule.allSuccess ? 100 :
                      (Number(schedule?.lastIndex + 1) / schedule?.steps.length) * 100
                  }
                  showInfo={false}
                />}
              </div>
            </div>
          </div>) : (
            <div className="invite-detail-item-third">
              <img
                className="more-invite-img"
                src={activityInfo.ladderRewardImage}
              />
              <div className="more-invite">
                {schedule?.subTitle && (
                  <div className="more-invite-title" style={!isLogin ? {marginTop: 0} : {}}>
                    {schedule?.subTitle[0]}
                  </div>
                )}
                <div className="progress-container" id="target-group">
                  <div className="target-group">
                    {schedule?.steps?.map((item, index) => (
                      <div
                        key={index}
                        className="target-group-item"
                      >
                        <img className="item-img" src={item.picture}/>
                        <div className="item-done-div" />
                        <div className="item-target" key={index}>
                          {item.target}
                        </div>
                      </div>
                    ))}
                  </div>
                  {schedule?.steps && <Progress
                    style={{
                      width: `${progressWidth}px`,
                      position: "absolute",
                      top: "70px",
                      left: 0
                    }}
                    strokeColor="#c92a1f"
                    trailColor="#fbe9c7"
                    percent={0}
                    showInfo={false}
                  />}
                </div>
              </div>
            </div>
          )}
          <div className="invite-detail-item">
            <img src={activityInfo.stepImg}/>
          </div>
          {isLogin && (
            <div className="invite-detail-item-third">
              <img className="item-top" src={activityInfo.achievementImg}/>
              <div className="item-reward-content">
                <div className="item-reward-content-detail">
                  {record?.map(
                    (item: { desc: string; number: number; unit: string }, index) => (
                      <div className="record" key={index}>
                        <div className="record-top">
                          <div className="record-top-num">{item.number || 0}</div>
                          <div className="record-top-unit">{item.unit}</div>
                        </div>
                        <div className="record-bottom">{item.desc}</div>
                      </div>
                    )
                  )}
                </div>
                <div
                  className="check-invite-detail"
                  onClick={() =>
                    (window.location.href = `./rewardDetail?sid=${sid}&taskId=${taskId}`)
                  }
                >
                  <img src={checkReward}/>
                </div>
              </div>
            </div>
          )}
          <div className="invite-detail-item-third">
            <img className="item-top" src={activityInfo.inviteRecord}/>
            <div className="invite-person">
              {(checkMore ? persons : persons?.slice(0, 10))?.map(item => (
                <div className="invite-person-item" key={item.nickName}>
                  <div className="item-left">
                    <div className="nick-name">{item.nickName}</div>
                    <div className="tag-name">{item.statusLeftDesc}</div>
                  </div>
                  <div className="item-right">{item.statusText}</div>
                </div>
              ))}
              {persons?.length > 10 && (
                <div
                  onClick={() => setCheckMore(!checkMore)}
                  className="learn-more"
                >
                  {!checkMore ? "显示更多" : "收起"}
                  <img
                    className={cx({"arrow-up": checkMore})}
                    src={learnMore}
                  />
                </div>
              )}
              {!(persons?.length > 0) && (
                <div className="null-person">{isLogin ? '您还没有邀请好友哦～' : '请登录后查看～'}</div>
              )}
            </div>
          </div>
          <div className="record-bottom-img-container">
            <img className="record-bottom-img" src={bottomImg}/>
          </div>
        </div>
      </div>) : (
        <div className="activity-expire">
          <img src={expireImg}/>
          <div className='activity-expire-content'>活动已结束</div>
        </div>
      )}
      <Modal className="rule-modal" visible={visible} footer={[null, null]} closable={false}>
        <div className="modal-content">
          <div className="modal-content-title">活动规则</div>
          <div className="modal-content-rule">
            {activityInfo?.ruleDesc}
          </div>
          <Button
            className="modal-content-btn"
            onClick={() => setVisible(false)}
          >
            知道了
          </Button>
        </div>
      </Modal>
      <Modal className="tip-modal" visible={checkVisible} footer={[null, null]} closable={false}>
        <div className="modal-content">
          <div className="modal-content-title">温馨提示</div>
          <div className="modal-content-tip">
            {activityInfo?.tocTip || '您不符合本次活动的参与条件详见活动规则'}
          </div>
          <Button
            className="modal-content-btn"
            onClick={() => setCheckVisible(false)}
          >
            知道了
          </Button>
        </div>
      </Modal>
    </div>
  );
};
