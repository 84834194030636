enum MCDNetworkStatusType {
  'wifi' = 1,
  '2g' = 2,
  '3g' = 3,
  '4g' = 4,
  '5g' = 5,
  'unknown' = -1,
  'none' = 0
}

// https://zhuanlan.zhihu.com/p/141656734

const hasSafeArea = (type: any) => {
  const iphoneTypeList = [
    { "model": "iPhone 6/7/8", "name": 'iPhone 6' },
    { "model": "iPhone 6", "name": 'iPhone 6' },
    { "model": "iPhone 7", "name": 'iPhone 7' },
    { "model": "iPhone 8", "name": 'iPhone 8' },
    { "model": "iPhone1,1", "name": "iPhone" },
    { "model": "iPhone1,2", "name": "iPhone3GS" },
    { "model": "iPhone3,2", "name": "iPhone 4" },
    { "model": "iPhone3,3", "name": "iPhone 4" },
    { "model": "iPhone4,1", "name": "iPhone 4S" },
    { "model": "iPhone5,1", "name": "iPhone 5" },
    { "model": "iPhone5,2", "name": "iPhone 5" },
    { "model": "iPhone5,3", "name": "iPhone 5c" },
    { "model": "iPhone5,4", "name": "iPhone 5c" },
    { "model": "iPhone6,1", "name": "iPhone 5s" },
    { "model": "iPhone6,2", "name": "iPhone 5s" },
    { "model": "iPhone7,1", "name": "iPhone 6 Plus" },
    { "model": "iPhone7,2", "name": "iPhone 6" },
    { "model": "iPhone8,1", "name": "iPhone 6s" },
    { "model": "iPhone8,2", "name": "iPhone 6s Plus" },
    { "model": "iPhone8,4", "name": "iPhone SE" },
    { "model": "iPhone9,1", "name": "iPhone 7" },
    { "model": "iPhone9,2", "name": "iPhone 7 Plus" },
    { "model": "iPhone9,3", "name": "iPhone 7" },
    { "model": "iPhone9,4", "name": "iPhone 7 Plus" },
    { "model": "iPhone10,1", "name": "iPhone 8" },
    { "model": "iPhone10,2", "name": "iPhone 8 Plus" },
    { "model": "iPhone10,4", "name": "iPhone 8" },
    { "model": "iPhone10,5", "name": "iPhone 8 Plus" },
  ]
  const typeIndex = iphoneTypeList.findIndex((item: any) => {
    return item.model === type
  });
  return typeIndex;
}

const getNetWorkType = () => {
  var ua:any = navigator.userAgent;
  var networkStr = ua.match(/NetType\/\w+/) ? ua.match(/NetType\/\w+/)[0] : 'NetType/other';
  networkStr = networkStr.toLowerCase().replace('nettype/', '');
  var networkType: any;
  switch (networkStr) {
    case 'wifi':
      networkType = 'wifi';
      break;
    case '4g':
      networkType = '4g';
      break;
    case '3g':
      networkType = '3g';
      break;
    case '3gnet':
      networkType = '3g';
      break;
    case '2g':
      networkType = '2g';
      break;
    default:
      networkType = 'other';
  }
  return new Promise((resolve, reject) => {
    resolve(MCDNetworkStatusType[networkType]);
  })
}

const getCt = () => {
  let ct = 10;
  const userAgent: any = navigator.userAgent.toLowerCase();
  if (userAgent.includes("mcd-ios")) {
    ct = 101;
  } else if (userAgent.includes("mcd-android")) {
    ct = 102;
  } else if (userAgent.includes('mcd-harmony')) {
    ct = 103;
  } else if (userAgent.includes("micromessenger")) {
    ct = 31;
  } else if (userAgent.includes("alipayclient")) {
    ct = 32;
  }
  return ct
}

export {
  hasSafeArea,
  getNetWorkType,
  getCt
}
