import React, { useState } from "react";
import apis from "@/common/net/apis";
import copy from "copy-to-clipboard";

const REDPACKET_COVER = "https://img.mcd.cn/gallery/154420210127024153136.png";
const PRESENT_COVER = "https://img.mcd.cn/gallery/ee7795ad87be447e.png";
const SEND_OUT_ICON = "https://img.mcd.cn/gallery/786520210128042258226.png";

export default (props: any) => {
  const wx: any = (window as any).wx;
  const {
    redpacket,
    chatId,
    wsid,
    activityCode,
    getRedpacketList,
    shareInfo,
    groupFlag,
    activityInfo,
    currentCgmStoreCode,
  } = props;
  const { styleType, salerId, storeCode, campaignCode } = activityInfo;
  const { status, leftCount, redpacketCode, shareUrl, isBind } =
    redpacket || {};
  const { repostMsg, shareImg, appId, rgmSendText } = shareInfo;
  const [isSending, setSendFlag] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastText, setToastText] = useState("");

  const sendRedpacket = () => {
    if (status === 3) {
      setShowToast(true);
      setToastText("很抱歉，红包不可发送");
      setTimeout(() => {
        setShowToast(false);
      }, 2000);
      return ;
    }
    rgmSendText && copy(rgmSendText);
    if (groupFlag === 0 && !isBind) {
      //只有可发送的时候才会呼起分享
      apis
        .checkRgmRedpacketSend(
          { activityCode: activityCode, redpacketCode: redpacketCode, storeCode: currentCgmStoreCode },
          { wsid: wsid }
        )
        .then((res: any) => {
          if (res && res.data && res.data.isSend) {
            sendChat();
          } else {
            setShowToast(true);
            setToastText("已发送给其他人或群，换个试试吧");
            setTimeout(() => {
              setShowToast(false);
              getRedpacketList(chatId, groupFlag, 1);
            }, 2000);
            return;
          }
        });
    } else if (groupFlag === 1 && status === 1) {
      sendChat();
    }
  };
  const bindRedpacket = () => {
    const params = {
      redpacketCode: redpacketCode,
      activityCode: activityCode,
      chatId: chatId,
      groupFlag: groupFlag,
      storeCode: currentCgmStoreCode,
    };
    apis.bindRgmRedpacket(params, { wsid: wsid }).then((res: any) => {
      if (res && res.data) {
        //绑定成功之后请求红包列表
        getRedpacketList(chatId, groupFlag, 1);
      }
    });
  };
  const sendChat = () => {
    if (isSending) {
      return;
    }
    if (rgmSendText) {
      setShowToast(true);
      setToastText("文案已复制，可粘贴到“留言”中发送");
      setTimeout(() => {
        setShowToast(false);
        invokeMsg();
      }, 1000);
    } else {
      invokeMsg();
    }

    setSendFlag(true);
  };

  const invokeMsg = () => {
    wx.invoke(
      "sendChatMessage",
      {
        msgtype: "miniprogram",
        enterChat: false,
        miniprogram: {
          appid: appId,
          title: repostMsg,
          imgUrl: shareImg,
          page: shareUrl,
        },
      },
      (res: any) => {
        setSendFlag(false);
        if (res.err_msg == "sendChatMessage:ok") {
          //分享成功之后如果还没绑定则去绑定
          !isBind && bindRedpacket();
        }
      }
    );
  };
  const getLeftCountStatus = (leftCount: any) => {
    let statusName = "";
    if (status === 3) {
      statusName = "";
    } else if (status === 2 || leftCount == 0) {
      statusName = "已领完";
    } else if (status === 1) {
      statusName = `剩余${leftCount}个可领`;
    }

    return statusName;
  };

  const getRedStatus = (status: any) => {
    let statusName = "";
    if (status === 4) {
      statusName = groupFlag === 1 ? "已发其他群" : "已发送";
    } else if (status === 3) {
      statusName = groupFlag === 1 ? "本群不可发" : "不可发";
    } else {
      statusName = "发送";
    }

    return statusName;
  };
  return (
    <>
      {showToast && (
        <div className="toast-wrapper">
          <div className="toast">{toastText}</div>
        </div>
      )}
      <div className="redpacket-item">
        <div className="redpacket-img-block">
          <img
            src={styleType == "2" ? PRESENT_COVER : REDPACKET_COVER}
            className="redpacket-img"
          />
          {status === 2 && (
            <img src={SEND_OUT_ICON} className="send-out-icon" />
          )}
        </div>
        <p className="left-count">{getLeftCountStatus(leftCount)}</p>
        <div
          className={
            (groupFlag === 0 && !isBind) || (groupFlag === 1 && status === 1)
              ? "send-btn"
              : "disable-send"
          }
          onClick={() => sendRedpacket()}
        >
          {getRedStatus(status)}
        </div>
      </div>
    </>
  );
};
