import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie';
import wechat from '@/common/wechat';
import '@/assets/styles/lottery/index.less';
import apis from '@/common/net/apis';
import tool from '@/common/utils/tool';

export default () => {
  const activityCode: any = tool.getSearchByKey('activityCode') || ''
  const exchangeCode: any = tool.getSearchByKey('exchangeCode') || ''
  const [wsid, setWsid] = useState(Cookies.get('wsid'))
  const [canTradeFlag, setCanTradeFlag] = useState(true)
  const [tradeInfo, setTradeInfo] = useState({ exchangeCode: '', rewardName: '', storeName: '' })
  const [noticeTitle, setNoticeTitle] = useState('')
  const [noticeDesc, setNoticeDesc] = useState('')
  const [showTradeInfo, setShowTradeInfo] = useState(true)

  useEffect(() => {
    document.title = '抽奖工具';
    document.body.addEventListener('touchmove', (e) => {
      e.preventDefault()
    }, {passive: false});
    (async () => {
      if (!wsid) {
        const _wsid: any = await wechat.genMCDSid();
        setWsid(_wsid);
      }
      checkTrade()
    })()
  }, [])
  useEffect(() => {
    checkTrade()
  }, [wsid])
  const checkTrade = () => {
    apis.lotteryCheck({ activityCode, exchangeCode }, { wsid }).then((res: any) => {
      const { data, code } = res

      setCanTradeFlag(data.canTradeFlag)
      setNoticeTitle(data.noticeTitle)
      setNoticeDesc(data.noticeDesc)
      setTradeInfo({
        exchangeCode: data.exchangeCode,
        rewardName: data.rewardName,
        storeName: data.storeName
      })
      if(!data.exchangeCode && !data.rewardName && !data.storeName) {
        setShowTradeInfo(false)
      }
    }).catch((res: any) => {
    })
  }

  const closePage = () => {
    window.close()
  }
  const onTrade = () => {
    apis.lotteryTrade({ activityCode, exchangeCode }, { wsid }).then((res: any) => {
      const { data } = res
      setShowTradeInfo(false)
      setNoticeTitle(data.noticeTitle)
      setNoticeDesc(data.noticeDesc)
      setCanTradeFlag(false)
    }).catch((res: any) => {
      setShowTradeInfo(false)

    })
  }
  return <div className="lottery-wrapper">
    <div className="lottery-pop">
      <div className="lottery-container">
        <div className="title">{noticeTitle}</div>
        {showTradeInfo ? <div className="lottery-block">
          {tradeInfo.exchangeCode && <div className="block-cell">
            <div className="cell-label">兑奖码：</div>
            <div>{tradeInfo.exchangeCode}</div>
          </div>}
          {tradeInfo.rewardName && <div className="block-cell">
            <div className="cell-label">奖品：</div>
            <div>{tradeInfo.rewardName}</div>
          </div>}
          {tradeInfo.storeName && <div className="block-cell">
            <div className="cell-label">核销餐厅：</div>
            <div>{tradeInfo.storeName}</div>
          </div>}
        </div> : <div className="trade-result-desc">{noticeDesc}</div>}
        {noticeDesc && showTradeInfo && <div className="notice-desc">{noticeDesc}</div>}
        {canTradeFlag ? <div className="btn-set">
          <div className="cancel-btn" onClick={closePage}>取消</div>
          <div className="ok-btn" onClick={onTrade}>确定</div>
        </div> : <div className="btn-set"><div className="ok-btn single-btn" onClick={closePage}>我知道了</div></div>}
      </div>
    </div>
  </div>
}