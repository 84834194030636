import React, { useEffect, useState } from "react";
import api from "@/common/net/apis";
import "../assets/styles/jumpOffer.less";
// @ts-ignore
// import wx from "weixin-js-sdk";

const { initCloud } = require("@wxcloud/cloud-sdk");

const cloud = initCloud();
let c: any = null;

const TakeOffer = () => {
  // console.log("jin页面");
  const [jumpPath, setJumpPath] = useState("");
  const [devType, setDevType] = useState(-1);

  // useEffect(() => {
  //   console.log("11111");
  //   importJs();
  //   console.log("js后");
  // }, []);

  useEffect(() => {
    docReady();
  }, []);

  const docReady = async () => {
    var ua = window.navigator.userAgent.toLowerCase();
    var isWXWork = /wxwork/i.test(ua);
    var isWeixin = !isWXWork && /micromessenger/i.test(ua);
    var isMobile = false;
    var isDesktop = false;
    if (
      window.navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|IEMobile)/i
      )
    ) {
      isMobile = true;
    } else {
      isDesktop = true;
    }
    console.log(
      "isWeixin",
      isWeixin,
      "isDesktop",
      isDesktop,
      "isMobile",
      isMobile
    );
    alert("isWeixin" + isWeixin + isDesktop);

    if (isWeixin) {
      setDevType(2);
      // console.log("hhh");

      // var launchBtn = document.getElementById("launch-btn");
      // launchBtn.addEventListener("ready", function (e) {
      //   console.log("开放标签 ready");
      // });
      // launchBtn.addEventListener("launch", function (e) {
      //   console.log("开放标签 success");
      // });
      // launchBtn.addEventListener("error", function (e) {
      //   console.log("开放标签 fail", e.detail);
      // });
      // const wx = (window as any).wx;

      const res = await api.getJumpWechatAuth({ url: window.location.href });
      console.log("res", res);
      const ret = res.data;
      // @ts-ignore
      wx.config({
        debug: true,
        appId: "wx4748d015a4cdd2ee",
        timestamp: ret.timestamp,
        nonceStr: ret.noncestr,
        signature: ret.signature,
        jsApiList: ["chooseImage"],
        openTagList: ["wx-open-launch-weapp"],
      });
    } else if (isDesktop) {
      // 在 pc 上则给提示引导到手机端打开
      setDevType(3);
    } else {
      setDevType(1);
      c = new cloud.Cloud({
        // 必填，表示是未登录模式
        identityless: true,
        // 资源方 AppID
        resourceAppid: "小程序 AppID", // <!-- replace -->
        // 资源方环境 ID
        resourceEnv: "云开发环境 ID", // <!-- replace -->
      });
      await c.init();

      var buttonEl = document.getElementById("public-web-jump-button");
      var buttonLoadingEl = document.getElementById(
        "public-web-jump-button-loading"
      );
      try {
        console.log("openweapp", c);
        await openWeapp(() => {
          // buttonEl?.classList.remove("weui-btn_loading");
          // buttonLoadingEl?.classList.add("hidden");
        });
      } catch (e) {
        buttonEl?.classList.remove("weui-btn_loading");
        buttonLoadingEl?.classList.add("hidden");
        throw e;
      }
    }
  };

  const openWeapp = async (onBeforeJump: any) => {
    const res = await c.callFunction({
      name: "public",
      data: {
        action: "getUrlScheme",
      },
    });
    alert("res" + res);
    if (onBeforeJump) {
      onBeforeJump();
    }
    window.location.href = res.result.openlink;
  };

  return (
    <div className="page full">
      {devType === 1 && (
        <div id="public-web-container" className="full">
          <p className="">正在打开 “填入你的小程序名称”...</p>
          <a
            id="public-web-jump-button"
            href="#"
            className="weui-btn weui-btn_primary weui-btn_loading"
            onClick={openWeapp}
          >
            <span
              id="public-web-jump-button-loading"
              className="weui-primary-loading weui-primary-loading_transparent"
            >
              <i className="weui-primary-loading__dot"></i>
            </span>
            打开小程序
          </a>
        </div>
      )}
      {devType === 2 && (
        <div id="wechat-web-container" className="full">
          <p className="">点击以下按钮打开 “填入你的小程序名称”</p>

          {/* @ts-ignore */}
          <wx-open-launch-weapp
            id="launch-btn"
            username="gh_26475a150a41"
            path="pages/testEntry/index"
            env-version="develop"
          >
            <script type="text/wxtag-template">
              <button>App内查看</button>
            </script>
            {/* @ts-ignore */}
          </wx-open-launch-weapp>
        </div>
      )}
      {devType === 3 && (
        <div id="desktop-web-container" className="full">
          <p className="">请在手机打开网页链接</p>
        </div>
      )}
    </div>
  );
};

export default TakeOffer;
