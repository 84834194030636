import React, { useEffect, useState, useRef } from "react";
import Cookies from "js-cookie";
import tool from "@/common/utils/tool";
import apis from "@/common/net/apis";
import { useParams } from "react-router-dom";
import config from "@/common/config/config";
import Top from "@/components/redpacket/Top";
import RedpacketList from "@/components/redpacket/RedpacketList";
import SingleRedpacketList from "@/components/redpacket/SingelRedpacketList";
import Footer from "@/components/redpacket/Footer";
import "@/assets/styles/redpacket/index.less";
import ampHandler from "@/utils/apm";

const APP_ID = "wx42cf5e73876fe3b9";
const AGENT_ID = config.AGENT_ID;
const LOADING_GIF = "https://img.mcd.cn/gallery/313420210205111604433.gif";
const PAGE_SIZE = 9;

export default () => {
  const wx: any = (window as any).wx;
  const code: any = tool.getSearchByKey("code");
  const fromRedirect: any = tool.getSearchByKey("fromRedirect") || 0;
  const { activityCode, styleType, activityName, beginTime, endTime, storeCode }: any =
    useParams();
  const [wsid, setWsid] = useState(Cookies.get("wsid"));
  const [isActivityEmpty, setIsActivityEmpty] = useState(false);
  const [chatId, setChatId] = useState("");
  const [isLogin, setIsLogin] = useState(true);
  // const [activityCode, setActivityCode] = useState('');
  const [redpacketLeftCount, setRedpacketLeftCount] = useState(0);
  const [rgmLeftLockCount, setRgmLeftLockCount] = useState(0);
  const [anotherBatchViable, setAnotherBatchViable] = useState(1); // 670新增 0置灰不可再抢一批  1可以再抢一批
  const [sendList, setSendList]: any = useState([]);
  const [unSendList, setUnsendList] = useState([]);
  const [otherGroupList, setOtherGroupList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [groupFlag, setGroupFlag] = useState(0);
  const [isStoreStaff, setStoreStaff] = useState(true);
  const [shareInfo, setShareInfo] = useState({
    shareImg: "",
    repostMsg: "",
    appId: "",
    rgmSendText: "",
  });
  const [activityInfo, setActivityInfo] = useState({});
  const [hasMore, setHasMore] = useState(false);
  const [singlePageNum, setSinglePageNum] = useState(1);
  const [singleSendTotal, setSingleSendTotal] = useState(0);
  const isInitialFLag = useRef(true);

  const newChatId = useRef("");
  newChatId.current = chatId;

  useEffect(() => {
    document.title = "社群红包";
  }, []);

  useEffect(() => {
    // 没有sid 或未登录时跳网页授权获取code
    if (!wsid || !isLogin) {
      if (!code) {
        //没有code去授权
        userOauth();
      } else {
        // 有code换取wsid
        getWsid(code);
      }
    }

    if (wsid && isLogin) {
      //config认证及agengConfig认证
      agentConfigApi();
    }
  }, [isLogin, wsid]);

  const userOauth = () => {
    let redirect_uri = encodeURIComponent(window.location.href);
    let href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APP_ID}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
    window.location.href = href;
  };

  const getWsid = (code: any) => {
    apis.wecomLogin({ code: code, agentId: AGENT_ID }).then((res: any) => {
      if (res && res.data && res.data.wsid) {
        setIsLogin(true);
        setWsid(res.data.wsid);
        Cookies.set("wsid", res.data.wsid, { expires: 15 });
      }
    });
  };

  const agentConfigApi = () => {
    apis.getWecomJsSignature({ url: window.location.href }).then((res: any) => {
      const { sign, timestamp, nonceStr } = res.data || {};
      setTimeout(() => {
        if (!newChatId.current && !fromRedirect && groupFlag === 1) {
          const currentLocation = window.location.href;
          window.location.href += `${
            currentLocation.includes("?") ? "&" : "?"
          }fromRedirect=1`;
        }
      }, 2000);
      wx &&
        wx.agentConfig({
          corpid: APP_ID,
          agentid: AGENT_ID,
          timestamp: timestamp,
          nonceStr: nonceStr,
          signature: sign,
          jsApiList: ["getCurExternalChat", "sendChatMessage", "getContext"],
          success: function (res: any) {
            ampHandler.uploadEventInfo(
              "agentConfigSuccess",
              Date.now(),
              "agentConfig success" + JSON.stringify(res)
            );
            wx.invoke("getContext", {}, (res: any) => {
              ampHandler.uploadEventInfo(
                "getContext",
                Date.now(),
                "getContext result" + JSON.stringify(res)
              );
              if (res.err_msg === "getContext:ok") {
                let entry = res.entry;
                if (entry === "group_chat_tools") {
                  setGroupFlag(1);
                  wx.invoke("getCurExternalChat", {}, (res: any) => {
                    ampHandler.uploadEventInfo(
                      "getCurExternalChat",
                      Date.now(),
                      "getCurExternalChat result" + JSON.stringify(res)
                    );
                    if (res.err_msg === "getCurExternalChat:ok") {
                      setChatId(res.chatId);
                      getRedpacketList(res.chatId, 1, 1);
                    } else {
                      setIsLoading(false);
                    }
                  });
                } else if (entry === "single_chat_tools") {
                  setGroupFlag(0);
                  getRedpacketList("", 0, 1);
                } else {
                  setGroupFlag(3);
                  setIsLoading(false);
                }
              }
            });
          },
          fail: (e: any) => {
            setIsLoading(false);
          },
        });
    });
  };

  const lockRgmRedpacket = (activityCode: any, chatId: any, groupFlag: any) => {
    apis
      .lockRgmRedpacket(
        {
          activityCode: activityCode,
          chatId: chatId,
          groupFlag: groupFlag,
          storeCode: storeCode,
        },
        { wsid }
      )
      .then((res: any) => {
        //更新页面数据 提示锁定成功，刷新页面
        updateRedpacketInfo(res.data || {}, 1, true, chatId, groupFlag);
      });
  };

  const getRedpacketList = (chatId: any, groupFlag: any, pageNum: any) => {
    return new Promise((resolve, reject) => {
      setSinglePageNum(pageNum);
      apis
        .getRgmRedpacketList(
          { activityCode, chatId, groupFlag, pageSize: PAGE_SIZE, pageNum, storeCode },
          { wsid }
        )
        .then((res: any) => {
          if (res) {
            setIsLoading(false);
            if (res.code === 401) {
              setIsLogin(false);
            }
            if (res.data) {
              updateRedpacketInfo(
                res.data || {},
                pageNum,
                false,
                chatId,
                groupFlag
              );
            } else {
              setIsActivityEmpty(true);
            }
            resolve(true);
          }
        })
        .catch((err: any) => reject(err));
    });
  };

  const updateRedpacketInfo = (
    redpacketInfo: any,
    pageNum: any = 1,
    isLock: any = false,
    chatId: any,
    groupFlag: any
  ) => {
    const {
      activityCode,
      redpacketLeftCount,
      rgmLeftLockCount,
      anotherBatchViable,
      sendList: newSendList,
      isActivityEmpty,
      shareImg,
      repostMsg,
      appId,
      isStoreStaff,
      sendTotal,
      rgmSendText,
    } = redpacketInfo;
    const list = newSendList || [];
    setSingleSendTotal(sendTotal);
    if ((pageNum - 1) * PAGE_SIZE + list?.length < sendTotal) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }
    let unSend = [];
    let defaultUnSendList = unSendList || [];
    let newOtherGroup = [];
    let otherGroup = otherGroupList || [];
    let resUnsendList = redpacketInfo.unSendList || [];
    let resOtherGroupList = redpacketInfo.otherGroupList || [];
    if (isLock) {
      unSend = [...defaultUnSendList, ...resUnsendList];
      newOtherGroup = [...otherGroup, ...resOtherGroupList];
    } else {
      unSend = redpacketInfo.unSendList || [];
      newOtherGroup = redpacketInfo.otherGroupList;
      if (pageNum === 1) {
        setSendList(list);
      } else {
        setSendList([...sendList, ...list]);
      }
    }
    setShareInfo({
      shareImg: shareImg,
      repostMsg: repostMsg,
      appId: appId,
      rgmSendText: rgmSendText,
    });
    setActivityInfo({
      salerId: redpacketInfo.salerId,
      styleType,
      storeCode: redpacketInfo.storeCode,
      campaignCode: redpacketInfo.campaignCode,
    });
    if (
      isInitialFLag.current &&
      !isActivityEmpty &&
      isStoreStaff &&
      unSend.length === 0 &&
      !newOtherGroup?.length &&
      !sendList?.length
    ) {
      isInitialFLag.current = false;
      let leftCount = getLeftCount(redpacketInfo);
      if (
        (groupFlag !== 1 || (groupFlag === 1 && leftCount <= 0)) &&
        rgmLeftLockCount > 0 &&
        anotherBatchViable !== 0
      ) {
        lockRgmRedpacket(activityCode, chatId, groupFlag);
      }
      // if (groupFlag !== 1 || (groupFlag === 1 && leftCount <= 0)) {
      //   lockRgmRedpacket(activityCode, chatId, groupFlag);
      // }
    }
    setIsActivityEmpty(isActivityEmpty);
    // setActivityCode(activityCode);
    setRedpacketLeftCount(redpacketLeftCount);
    setRgmLeftLockCount(rgmLeftLockCount);
    setAnotherBatchViable(anotherBatchViable || 1);
    setUnsendList(unSend);
    setOtherGroupList(newOtherGroup);
    setStoreStaff(isStoreStaff);
  };

  const getLeftCount = (list: any) => {
    const { sendList = [], unSendList = [], otherGroupList = [] } = list;
    if (sendList === null && otherGroupList === null && unSendList === null) {
      return -1;
    }
    let sendLeftCount = sendList?.filter((i: any) => i.leftCount > 0).length;
    let unSendLeftCount = unSendList?.filter(
      (i: any) => i.leftCount > 0
    ).length;
    let otherLeftCount = otherGroupList?.filter(
      (i: any) => i.leftCount > 0
    ).length;
    return sendLeftCount + unSendLeftCount + otherLeftCount;
  };

  return (
    <div
      className="redpacket-wrapper"
      style={{
        height: isActivityEmpty || !chatId ? "100vh" : "calc(100vh - 68px)",
        paddingBottom: isActivityEmpty ? 0 : "68px",
      }}
    >
      {isLoading ? (
        <div className="loading">
          <img className="loading-img" src={LOADING_GIF} />
        </div>
      ) : (
        <>
          <div className="line"></div>
          <Top
            isActivityEmpty={isActivityEmpty}
            chatId={chatId}
            groupFlag={groupFlag}
            isStoreStaff={isStoreStaff}
            activityName={activityName}
            beginTime={beginTime}
            endTime={endTime}
          />
          {!isActivityEmpty && chatId && groupFlag === 1 && (
            <RedpacketList
              sendList={sendList}
              unSendList={unSendList}
              otherGroupList={otherGroupList}
              chatId={chatId}
              activityCode={activityCode}
              getRedpacketList={getRedpacketList}
              wsid={wsid}
              shareInfo={shareInfo}
              groupFlag={groupFlag}
              activityInfo={activityInfo}
              currentCgmStoreCode={storeCode}
            />
          )}
          {!isActivityEmpty &&
            isActivityEmpty !== null &&
            isStoreStaff &&
            groupFlag === 0 && (
              <SingleRedpacketList
                sendList={sendList}
                unSendList={unSendList}
                otherGroupList={otherGroupList}
                chatId={chatId}
                activityCode={activityCode}
                getRedpacketList={getRedpacketList}
                wsid={wsid}
                shareInfo={shareInfo}
                groupFlag={groupFlag}
                pageNum={singlePageNum}
                hasMore={hasMore}
                sendTotal={singleSendTotal}
                activityInfo={activityInfo}
                currentCgmStoreCode={storeCode}
              />
            )}
          {!isActivityEmpty &&
            ((isStoreStaff && groupFlag === 0) || groupFlag === 1) && (
              <Footer
                redpacketLeftCount={redpacketLeftCount}
                rgmLeftLockCount={rgmLeftLockCount}
                anotherBatchViable={anotherBatchViable}
                chatId={chatId}
                activityCode={activityCode}
                sendCount={sendList && sendList.length}
                unSendCount={unSendList && unSendList.length}
                otherGoupCount={otherGroupList && otherGroupList.length}
                updateRedpacketInfo={updateRedpacketInfo}
                wsid={wsid}
                groupFlag={groupFlag}
                sendTotal={singleSendTotal}
                storeCode={storeCode}
              />
            )}
        </>
      )}
    </div>
  );
};
